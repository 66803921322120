@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

:root {
    --primary-font:'Poppins';
    --secondary-font:'Montserrat';
    --main-text-color: #ffffff;
    --secondary-text-color: #3F4254;
    --bgcolor: #E0EFFF;
    --textcolor: #5E5873;
    --table-theme-color: #B5B5C3;
    --maincolor:#5458AF;
    --secondary-color:#404386;
    --menu-icon-hover: #41448d;
    --cardprimarycolor: #5458af;
    --selected-card-color: #2F316B;
    --hover-card-color: #41448d;
    --sidetopnav-color:#217cd6;
    --topnav-color:#ffffff;
    --footer-color:#ffffff;
    --body-color:#eef0f8;
    --actionbtn-color: #A2A5B5;
    --actionbtn-bg-color: #F6F8FA;

    --maincolor-cmc:#2980B9;
    --secondary-cmc:#005884;
    --menu-icon-hover-cmc: #046FA4;
    --cardprimarycolor-cmc: #2980B9;
    --selected-card-color-cmc: #005884;
    --hover-card-color-cmc: #016799;

  }

.font{
    font-weight: bold;
    font-size: small;
}
body{
  font-family: var(--primary-font);
  background-color:  var(--body-color);
}

/* --------------old css---------------- */
 .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  



