/* Sidebar.css */

.sidebar {
    height: 100%;
    width: 50px;
    position: fixed;
    top: 50px;
    left: 0;
    background-image: linear-gradient(#2D1366, #2A73C1);
    padding-top: 20px;
    z-index: 20;
    transition: width 0.2s;
    overflow: hidden;
}

#cmc .sidebar {
    height: 100%;
    width: 50px;
    position: fixed;
    top: 50px;
    left: 0;
    background-color: var(--maincolor-cmc);
    padding-top: 20px;
    z-index: 20;
    transition: width 0.2s;
    overflow: hidden;
}

.sidebar.expanded,
#cmc .sidebar.expanded {
    width: 200px;
}

/* .sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #ffffff;
} */

.sidebar ul li a {
    padding: 15px;
    font-size: 1em;
    display: flex; /* Use flex to align icon and text */
    align-items: center; /* Center items vertically */
    color: #ffffff;
    text-decoration: none;
}

.sidebar ul li a:hover {
    color: #fff;
    background: var(--menu-icon-hover);
}

.sidebar ul li.active {
    background-color: var(--menu-icon-hover);
}

#cmc .sidebar ul li a:hover {
    color: #fff;
    background: var(--menu-icon-hover-cmc);
}

.sidebar ul li a > * {
    margin-right: 8px; /* Adjust the margin as needed */
}

.toggle-container {
    display: flex;
    justify-content: left;
    align-items: left;
    margin-bottom: 15px; /* Adjust as needed */
}

.sidebar-toggle {
    width: 30px;
    height: 30px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #fff;
    margin-left: 8px;
}

/* @media (max-width: 768px) {
    .sidebar {
        width: 250px;
        margin-left: -250px;
    }

    .sidebar.expanded {
        margin-left: 0;
    }
} */
/* sidebar.css */

/* Existing styles for larger screens */

/* Adjust styles for screens up to 768px */
/* Sidebar.css */

/* Existing styles for larger screens */

/* Adjust styles for screens up to 768px */
@media (max-width: 768px) {
    .sidebar,
    #cmc .sidebar {
        width: 0; /* Hide the sidebar */
        overflow: hidden;
        transition: 0.2s;
    }

    .sidebar.expanded {
        width: 0;
        overflow: hidden; /* Show the sidebar when expanded */
    }

}

@media (min-width: 768px) {
    .kt_header_mobile_toggle {
      display: none;
    }
  }
  
  @media (min-width: 768px) {
    .burger-icon {
      display: none;
    }
  }