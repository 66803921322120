.thead {
  font-weight: bold !important;
  font-size: 0.875rem !important;
}

.tData {
  font-size: 0.875rem !important;
}

.note {
  color: red;
  display: flex;
  flex-direction: row-reverse;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Adjust the height as needed */
}

.loading-container-subscription {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  /* Adjust the height as needed */
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.insert-btn {
  margin: 0 1px;
}

/* For all tables */

.css-1ygcj2i-MuiTableCell-root {
  font-family: "Poppins", sans-serif !important;
}

.css-1ex1afd-MuiTableCell-root {
  font-family: "Poppins", sans-serif !important;
}

/* Update Student Information modal */
.css-1w7ywiy-MuiSpeedDial-root.MuiSpeedDial-directionDown,
.css-1w7ywiy-MuiSpeedDial-root.MuiSpeedDial-directionRight {
  top: 16px;
  left: 46% !important;
}


/* Student.js */
.public-view-question-btn,
.public-edit-question-btn,
.view-public-assement-btn,
.edit-public-assement-btn,
.setup-public-assement-btn,
.grade-public-assement-btn,
.share-question-btn,
.edit-question-btn,
.view-question-btn,
.view-assement-btn,
.edit-assement-btn,
.flaticon-delete {
  background-color: #87878712 !important;
  color: #A2A5B5 !important;
  border-color: #F6F8Fa !important;
}

.public-view-question-btn:hover,
.public-edit-question-btn:hover,
.view-public-assement-btn:hover,
.edit-public-assement-btn:hover,
.setup-public-assement-btn:hover,
.grade-public-assement-btn:hover,
.share-question-btn:hover,
.edit-question-btn:hover,
.view-question-btn:hover,
.view-assement-btn:hover,
.edit-assement-btn:hover,
.send-assement-btn:hover {
  background-color: #EDEEF7 !important;
  color: var(--maincolor) !important;
  border-color: #EDEEF7 !important;
}

#cmc .public-view-question-btn:hover,
#cmc .public-edit-question-btn:hover,
#cmc .view-public-assement-btn:hover,
#cmc .edit-public-assement-btn:hover,
#cmc .setup-public-assement-btn:hover,
#cmc .grade-public-assement-btn:hover,
#cmc .share-question-btn:hover,
#cmc .edit-question-btn:hover,
#cmc .view-question-btn:hover,
#cmc .view-assement-btn:hover,
#cmc .edit-assement-btn:hover,
.send-assement-btn:hover {
  background-color: #E5F5FF !important;
  color: var(--maincolor-cmc) !important;
  border-color: #E5F5FF !important;
}

.flaticon-delete:hover {
  background-color: #FFF4F5 !important;
  color: #FF6071 !important;
  border-color: #FFF4F5 !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

/* .css-10d9dml-MuiTabs-indicator {
  background-color: #5458AF !important;
} */

.form-select {
  height: calc(1.5em + 1.3rem + 2px) !important;
  padding: 0px 0px 0px 10px !important;
}

.react-datePicker {
  width: 100%;
}

.disabled-select {
  background-color: #f3f6f9;
  border: 1px solid #bababa;
}

.hide-card-header {
  display: none;
}

.payment-amount {
  text-align: end !important;
}

.payment-total {
  padding: 20px 10px;
  border: 1px solid #F3F4F6;
  border-left: 3px solid #7679bf;
  border-radius: 5px;
  background-color: #FAFBFC;
  font-weight: 600;
  margin-top: 10px;
}

.discount-total {
  padding: 20px 10px;
  border: 1px solid #fafcfb;
  border-left: 3px solid #1bc598;
  border-radius: 5px;
  background-color: #fafcfb;
  font-weight: 600;
  margin-top: 10px;
}

.outstanding-total {
  padding: 20px 10px;
  border: 1px solid #fffff8;
  border-left: 3px solid #FFA800;
  border-radius: 5px;
  background-color: #fffff8;
  font-weight: 600;
  margin-top: 10px;
}

.main-payment-total {
  padding: 10px 10px;
  border: 1px solid #F3F4F6;
  border-left: 3px solid #7679bf;
  border-radius: 5px;
  background-color: #FAFBFC;
  font-weight: 600;
}

#exampleFormControlTextarea1::placeholder {
  font-family: 'Poppins', sans-serif;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}