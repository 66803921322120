/* signup.css */

/* Global styles */
body {
    /* font-family: Arial, sans-serif; */
    margin: 0;
    padding: 0;
  }

  .navigation-bg {
    background-color: #FFF;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }
  
  /* Container styles */
  .login-edsider-background {
    background-color: #f2f2f2;
    /* min-height: 152vh; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url('../../../../assets/images/login-bg.png');
    background-size: cover;
  }

  .login-cmc-background {
    background-color: #f2f2f2;
    /* min-height: 152vh; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url('../../../../assets/images/cmclogin-bg.svg');
    background-size: cover;
  }
  
  .container {
    padding-bottom: 5rem;
  }
  
  /* Card styles */
  .login-card {
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 30px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .login-card-title {
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  
  .login-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .request-form-para {
    text-align: justify;
  }

  .req-form-name {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  /* Form styles */
  form {
    width: 100%;
  }
  
  .form-group {
    margin-bottom: 2rem;
  }
  
  form label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  form input[type="text"],
  form input[type="email"] {
    font-family: "Poppins", "sans-serif";
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s ease;
  }
  
  form input[type="text"]:focus,
  form input[type="email"]:focus {
    outline: none;
    border-color: #7779CF;
    box-shadow: 0 0 0 3px rgba(102, 0, 255, 0.3);
  }
  
  .form-error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  .form-success-message {
    color: green;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  /* Button styles */
  button[type="submit"] {
    background-color: #5458af;
    color: #fff;
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #5458af;
  }
  
  /* Spinner styles */
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
  
  /* .spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
   */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .footer-container {
    font-size: 1rem;
    padding: 10px 0;
    color: #2C3440;
    letter-spacing: -0.04375rem;
    background-color: #FFF;
  }
