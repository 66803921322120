.switch input:empty ~ span:before, .switch input:empty ~ span:after {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    content: ' ';
    -webkit-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    background-color: rgb(204, 204, 204);
}

.switch input:empty ~ span:after {
    opacity: 1;
    color: #ffffff;
    background-color: #ffffff;
}

.switch input:checked ~ span:after {
    opacity: 1;
    color: #ffffff;
    background-color: #3699FF;
}

