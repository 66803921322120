.settings-menu {
  width: 20px;
  height: 50px;
  position: absolute;
  background-color: #fff;
  color: var(--maincolor);
  border-radius: 2px;
  cursor: pointer;
  border: none;
}

#cmc .settings-menu {
  width: 20px;
  height: 50px;
  position: absolute;
  background-color: #fff;
  color: var(--maincolor-cmc);
  border-radius: 2px;
  cursor: pointer;
  border: none;
}

@media (max-width: 1000px) {
  .settings-modal {
    display: none;
  }
}