/* siteannouncement.css */

.custom-header {
  background-color: #5458af;
  color: #fff;
}

/* progressbar.css */
.custom-progress-bar .progress-bar {
  background-color: #5458af;
}

/* tiles.css */
.DashboardTiles {
  margin: 20px;
}

.tilePaper-dashboard {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tileTitle-dashboard {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.tileContent-dashboard {
  display: flex;
  align-items: center;
}

.tileIcon-dashboard {
  margin-right: 10px;
}

.tileCount-dashboard {
  font-size: 1.5rem;
  font-weight: bold;
}

/* 
Best-selling-courses.css */

.best-selling-courses {
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.best-selling-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.best-selling-th {
  background-color: #5458af;
  color: #fff;
}

.best-selling-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.best-selling-table th {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.best-selling-table tr:hover {
  background-color: #d9daf1;
}

/* .chart-container {
overflow-x: auto;
width: 100%;
margin-bottom: 20px; 
} */

/* managedashboard.css */
.scrollable-chart {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
}

.progress-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
}

.doughnutchart-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
}

.coursetable-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: 380px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.attendance-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.drilldown-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.doughnutchart1-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: 380px;
  padding-top: 20px;
}

/* drillchart.css */

.content-drillchart th,
.content-drillchart td {
  border: 1px solid #d9daf1;
  padding: 8px;
  text-align: left;
}

.content-drillchart th {
  background-color: #fffdfd;
}

.content-drillchart tr:hover {
  background-color: #d9daf1;
}

.content-drillchart.level-1 tr {
  background-color: #e9e9f5;
}

.content-drillchart.level-2 tr {
  background-color: #e9e9f5;
}

.content-drillchart.level-3 tr {
  background-color: #e9e9f5;
}

.container-drillchart {
  border: 1px solid #ccc;
  height: 300px;
  max-width: 600px;
  padding: 10px;
  margin: 20px auto;
  background-color: #d9dbf1;
  border-radius: 5px;
  overflow-y: auto;
}

.content-drillchart {
  margin-top: 6px;
  margin-bottom: 10px;
  border-collapse: collapse;
  width: 100%;
}

.top-right-buttons-drillchart {
  position: absolute;
  top: 0;
  right: 10px;
  margin: 10px;
}

.top-right-buttons-drillchart button {
  color: #ffffff;
  background-color: #545cb4;
  border: none;
  border-radius: 5px;
  padding: 10px 12px;
  margin-right: 5px;
  cursor: pointer;
}

/* 
.row .content-drillchart:hover {
background-color: #f0f0f0;
}
.content-drillchart.level-1 .row {
background-color: #f0f0f0; 
}

.content-drillchart.level-2 .row {
background-color: #e0e0e0;
}

.content-drillchart.level-3 .row {
background-color: #d0d0d0; 
}

.content-drillchart .row:hover {
background-color: #f5f5f5; 
}  */

/* 
attendance.css */

.attendence-chart {
  margin-top: 50px;
}

/* notice-board.css */
.notice-board {
  margin: 10px;
}

#notice-board-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
}

#notice-card-header {
  background-color: #5458af;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

#notice-header-title {
  margin: 0;
  color: white;
}

#notice-card-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.sender-info {
  flex-grow: 1;
}

.sender-name {
  font-weight: bold;
  margin: 0;
}

.sender-role {
  margin: 0;
}

.notice {
  margin: 0;
}

.quickguide-container {
  padding: 30px 0px;
  border: 1px solid #c8c9f9;
  border-radius: 5px;
}

.quickguide-button {
  border: 1px solid #c8c9f9;
  padding: 5px 15px;
  background-color: #f9f9ff;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  margin-top: 5px;
  font-size: 12px;
  color: var(--maincolor);
  font-weight: 500;
}

/* New Student Dashboard */

.dashboard__welcome {
  background-color: #f9f9ff;
  border: 1px solid #c8c9f9;
  border-radius: 5px;
  padding: 10px 15px;
}

.dashboard__welcome-text {
  font-weight: 500;
  color: var(--maincolor);
  font-size: 18px;
}

.dashboard__container {
  border: 2px solid #edeff7;
  border-radius: 5px;
  padding: 10px 15px;
}

.dashboard__container-title {
  display: block;
  color: #303030;
  font-size: 14px;
  font-weight: 600;
}

.dashboard__container-text {
  font-size: 10px;
  color: #5f6276;
}

.announcement__card {
  border-top: 1px solid #edeef7;
  border-bottom: 1px solid #edeef7;
  padding: 12px 0px;
}

.announcement__title,
.document__title {
  font-weight: 500;
  font-size: 13px;
}

.document__icon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: #f0f0ff;
}

.announcement__breadcrumb,
.document__breadcrumb {
  font-size: 8px !important;
  font-weight: 500 !important;
}

.announcement__content,
.document__content {
  font-size: 10px;
}

.dashboard__count-cards-pink {
  background-image: linear-gradient(to right, #d53364, #f249a4);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #ffcedd;
}

.dashboard__count-cards-blue {
  background-image: linear-gradient(to right, #7f83db, #84c1e1);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #edeef7;
}

.dashboard__count-cards-green {
  background-image: linear-gradient(to right, #4aa96d, #7dc3ab);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #ceffee;
}

.dashboard__count-cards-purple {
  background-image: linear-gradient(to right, #7665a8, #ad569b);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #d7caff;
}

.dashboard__count-cards-orange {
  background-image: linear-gradient(to right, #f17a41, #ffce60);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #fce5af;
}

.dashboard__count-cards-dodger {
  background-image: linear-gradient(to right, #238bce, #00c6ff);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #c6f3ff;
}

.dashboard__user-text {
  color: #fff;
  font-weight: normal;
  font-size: 10px;
}

.dashboard__user-count {
  color: #fff;
}

.chart-container {
  flex-wrap: wrap;
}

/* Receivables */
.receivables-row {
  padding: 8px 10px;
  border-bottom: 1.2px solid #edeff7;
}
.receivables-title {
  color: #939393;
  font-size: 12px;
  font-weight: 500;
}

.receivables-amount {
  font-weight: bold;
}

/* Revenue */
.revenue-container {
  background-image: linear-gradient(to right, #f17a41, #ffce60);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #fce5af;
}

.revenue-text {
  color: #fff;
  font-size: 10px;
}

.revenue-amount {
  color: #fff;
}

.revenue__icon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 207, 96, 0.2);
}

.dashboard-subheading {
  font-size: 12px;
  color: #2d2d2d;
  font-weight: 600;
}

.revenue-row-text {
  font-size: 12px;
}

/* Payment Overdue */
.payment-overdue-container {
  background-color: #FAFBFC;
  border-radius: 5px;
  border-left: 4px solid #238bce;
}

.reminder-text {
  font-size: 10px;
  color:  #A2A5B5;
}