.thead {
  font-weight: bold !important;
  font-size: 0.875rem !important;
}

.tData {
  font-size: 0.875rem !important;
}

.stu-thead {
  font-weight: bold !important;
  font-size: 0.875rem !important;
}

.stu-tData {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: -0.04rem !important;
}

.stu-variant-class {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.2px;
}

/* Enrolled Papers */

.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  font-weight: 400;
  color: var(--table-theme-color) !important;
  font-size: 0.875rem;
  letter-spacing: -0.01rem;
  text-transform: unset;
}

.css-ahj2mt-MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

.select-student-dropdown {
  height: 43px !important;
}