.container-terms{
    margin: 50px auto 20px auto;
    width: 80%;
    padding: 30px 50px 50px 50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 20px;
    text-align: justify;
}

hr{
    height: 1px;
    background-color: #ccc;
    border: none;
}

.buttons{
    margin-top: 10px;
    text-align: center;
}
