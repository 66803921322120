.wizard-title {
  color: #000000 !important;
  font-size: 14px !important;
}

.wizard-desc {
  color: #000000 !important;
}

.navi-text {
  font-weight: 400 !important;
  font-size: 1.1rem !important;
  margin-bottom: 5px;
}

/* ExamGradeReport.js */
.access-grade-report {
  color: #8c8c96;
  font-size: 1.2rem;
  font-weight: 500;
  /* margin-left: 1rem; */
  margin-top: 0.5rem;
}

.highlighted {
  background-color: #f0f8ff;
  border: 2px solid #007bff;
  padding: 10px;
  transition: background-color 0.5s ease;
}

/* Questions.js */

.exam-header {
  background-color: #cde4ff;
  border-radius: 5px;
  margin-right: 12px;
  margin-left: 12px;
  padding-top: 20px;
}

.time-count-text {
  font-size: 15px !important;
}

.time-remaining-text {
  color: #252525 !important;
  font-weight: 600;
}

.paper-header {
  margin: -12px 12px -26px 12px;
  background-color: #cde4ff;
  padding-top: 20px;
  border-radius: 5px;
}

.btn-primary {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 13px;
}

.btn-view-questions,
.answer-all-questions {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #252525 !important;
}

.questions-section {
  border-radius: 5px;
  background-color: #cde4ff;
}

.summary-section {
  border-radius: 5px;
  background-color: #d9ffeb;
}

.btn-unanswered-question {
  background-color: #fff;
  margin: 6.5px !important;
}

.btn-icon {
  width: 28px !important;
  height: 28px !important;
}

.btn-unanswered-question:hover {
  background-color: #f2f2f2;
}

/* Modal.js */

/* .modal {
  background-color: rgba(0, 0, 0, 0.547);
} */

/* PaperQuestions.js */

.paper-btn {
  display: flex;
  align-items: center;
}

.fixed {
  position: fixed;
  bottom: 80px;
  left: 55px;
  z-index: 1;
}

.parent-class {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}

.single-column {
  grid-template-columns: repeat(1, 1fr) !important;
}

/* For the images on the question paper */
figure {
  margin: 1em 5px;
}

/* Screen larger than 600px? 2 column */
@media (max-width: 575.98px) {
  .fixed {
    bottom: 55px;
  }
}

@media (min-width: 600px) {
  .parent-class {
    grid-template-columns: repeat(2, 1fr);
  }

  .fixed {
    bottom: 75px;
  }
}

/* Screen larger than 900px? 4 columns */
@media (min-width: 900px) {
  .parent-class:not(.single-column) {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* PaperReview.js */
.checkbox>input.correct-color:checked~span {
  background-color: #00a86b;
}

.question-container {
  border: 2px solid #EDEEF7;
  margin: 20px 20px;
  padding: 20px 0px;
  border-radius: 5px;
}

.answer-sheet-text {
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
}

.model-guidance-text {
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

.answersheet-correct {
  border: 1px solid #2cd59c;
  background-color: #e5faf3;
  color: #2cd59c;
  border-radius: 3px;
}

.answersheet-wrong {
  border: 1px solid #FF6071;
  background-color: #FFEFF0;
  color: #FF6071;
  border-radius: 3px;
}

.submission-document {
  font-family: "Poppins", sans-serif;
}

.submission-document:hover {
  text-decoration: underline;
  color: var(--maincolor);
}

/* PaperGradeReport.js */

.exam-details {
  border-left: 3px solid var(--maincolor);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #FAFBFC;
  padding: 20px 0px;
}

.grade-report-box {
  background-color: #f6f8fa;
  padding: 10px 10px;
  border-radius: 0.425rem;
  border: 0.5px solid #edf1fe;
}

.grade-report-text {
  color: var(--textcolor);
}

/* Self Grade */
.rdw-image-center {
  display: flex;
  justify-content: start !important;
}

/* PaperMapping.js */
.sticky-card-body {
  background-color: #fff;
  z-index: 1;
}

.sub-view-editor .editorClassName {
  overflow: hidden !important;
}

.rdw-editor-main {
  overflow: hidden !important;
}

.boxIndicators {
  position: sticky;
  top: 76px;
  right: 16px;
}

.form-check {
  padding: 0px;
}

.AllQcheckBox {
  padding-left: 16px;
}

.view {
  margin: 0px;
}

.singlePgview {
  margin: 10px 0px !important;
}

.timeLeft {
  padding: 5px 0px;
}

.dashboardEnrolledPath {
  margin-top: 10vh;
  right: 0px;
  width: 90%;
}

.paperDatas {
  width: 90%;
}

.Qsummary {
  width: 90%;
}