:root {
  --deletecolor: #DEEBFF;
  --viewcolor: #DEEBFF;
  --iconcolor: #fff;
  --bordercolor: #edeff7;
}

/* Hirearchy cards */

.hirearchy-card .card-custom {
  border: 1.5px solid #edeff7;
  border-radius: 10px;
  margin: 3px;
}

.hirearchy-card .add-card {
  background-color: var(--bordercolor);
  height: 65px;
  border-radius: 10px;
}

#cmc .hirearchy-card .add-card {
  background-color: var(--bgcolor);
  height: 65px;
  border-radius: 10px;
}

.card-section {
  height: 100vh;
  overflow-y: auto;
}

.tab-section {
  height: 100vh;
  border: 1.5px solid var(--bordercolor);
  border-radius: 10px;
}

.card-custom .hcard-header {
  justify-content: center;
  padding: 12px;
  background-color: var(--cardprimarycolor);
  border: 1.5px solid var(--cardprimarycolor);
  border-radius: 10px;
  /* border-top-left-radius: 0.42rem; */
  /* height: 80px; */
}

#cmc .card-custom .hcard-header {
  justify-content: center;
  padding: 12px;
  background-color: var(--cardprimarycolor-cmc);
  border: 1.5px solid var(--cardprimarycolor-cmc);
  border-radius: 10px;
}

/* .card-custom .selected-card {
  background-image: linear-gradient(to right,#35310a , #726B17 , #a39d56 );
  box-shadow:0px 0px 1px 1px rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  border: 1px solid #ebe711; 
} */

.card-custom .selected-card {
  background-color: #0b0355;
  border: 2px solid #ebe711;
  box-shadow: none;
  transform: scale(1.05); /* Slightly enlarges the card on selection */
  transition: transform 0.3s ease, background-color 0.3s ease, border 0.3s ease;
}
 
#cmc .card-custom .selected-card {
  background-color: var(--selected-card-color-cmc);
}

.card-custom .hcard-header.hoverable:hover {
  /* transform: scale(1.02); */
  background-color: #060B79;
  cursor: pointer;
}

#cmc .card-custom .hcard-header.hoverable:hover {
  /* transform: scale(1.02); */
  background-color: var(--hover-card-color-cmc);
  cursor: pointer;
}

.card-custom .hcard-title {
  display: flex;
  color: #fff;
  font-size: 1rem;
}

.card-custom .hcard-subtitle {
  color: #fff;
  font-size: 10px;
  font-weight: 200;
}

.expand-icons {
  color: #fff;
}

/* .form-container {
  height: 160px;
  overflow-y: auto;
} */

.form-list {
  font-size: 12px;
  background-color: transparent;
  border: none;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: start;
  text-align: start;
}

.form-list:hover {
  color: var(--cardprimarycolor);
}

.icon-box {
  background-color: var(--cardprimarycolor);
  padding: 5px;
  border-radius: 5px;
  border: none;
}

.view-button {
  background-color: var(--viewcolor);
}

.action-icons-edit {
  padding: 3px;
  border-radius: 3px;
  background-color: rgba(222, 235, 255, 0.35);
  border: none;
  margin-bottom: 2px;
}

.action-icons-delete {
  padding: 3px;
  border-radius: 3px;
  background-color: rgba(222, 235, 255, 0.35);
  border: none;
  margin-bottom: 2px;
}

.action-icons-view {
  padding: 4px;
  border-radius: 2px;
  margin-right: 8px;
  background-color: var(--viewcolor);
  border: none;
}

.form-view {
  color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-out;
  animation-fill-mode: both;
  visibility: visible;
}

.fade-in:nth-child(1) {
  animation-delay: 0.2s;
}

.fade-in:nth-child(2) {
  animation-delay: 0.4s;
}

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--cardprimarycolor) !important;
} */

/* .css-1aquho2-MuiTabs-indicator {
  background-color: var(--cardprimarycolor) !important;
} */

.menu-icon {
  width: 40px;
  height: 40px;
  background-color: var(--cardprimarycolor);
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
  border: none;
}

#cmc .menu-icon {
  width: 40px;
  height: 40px;
  background-color: var(--cardprimarycolor-cmc);
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
  border: none;
}

@media (max-width: 820px) {
  .card-section-hidden {
    display: none;
  }

  .card-section.visible {
    display: block;
  }
}

@media (min-width:899px) {
  .menu-icon {
    display: none;
  }
}

@media (max-width: 900px) {
  .card-section {
    display: none;
  }
}

/* Top Navigation */

.topnav-container {
  background-color: var(--cardprimarycolor);
  margin-top: 60px;
  margin-bottom: 50px;
}

.menu-list {
  display: flex;
  list-style: none;
  padding: 5px;
  margin: 1rem 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.menu-list .menu-item {
  padding: 0 20px;
}

.menu-list .menu-item .menu-text {
  color: #edeff7;
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .topnav-container {
    margin-top: 55px;
  }

  .menu-list .menu-item {
    padding: 0 10px;
  }
}

/* Medium devices (tablets, 601px to 900px) */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .topnav-container {
    margin-top: 55px;
  }
}

/* Large devices (desktops, 901px and up) */
@media only screen and (min-width: 901px) {
  .topnav-container {
    margin-top: 65px;
  }
}

/* Breadcrumbs.js */
/* .css-1jbmdz7-MuiButtonBase-root-MuiChip-root {
  background-color: #fff !important;
} */

/* Search.js */


.css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--cardprimarycolor) !important;
}

.selected-tab-color {
  color: var(--cardprimarycolor) !important;
}

#cmc .selected-tab-color {
  color: var(--cardprimarycolor-cmc) !important;
}

.hirearchy-card {
  margin-bottom: 10px;
  min-width: 216px;
}