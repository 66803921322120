@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500;600;700;900&family=Poppins:wght@200&display=swap");

:root {
  /* FONTS */
  --CMC-FF: "Darker Grotesque", sans-serif;
  --CMC-HEADING: 20rem;
  --CMC--SUBHEADING: 5rem;

  /* COLORS */
  --CMC-FONT: #191919;
  --CMC-GRADIENT: linear-gradient(to right,
      var(--DARKBLUE),
      var(--PRIMARYBLUE));
  --PURPLE: #9a7bff;
  --TURQUOISE: #4df4ff;
  --LIGHTGREEN: #e4f1ee;
  --LIGHTORANGE: #fff6e5;
  --LIGHTTURQUOISE: #e2fafd;
  --LIGHTBLUE: #e1e9ff;
}

img {
  pointer-events: none;
}

.cmc-landing {
  background-image: url(../images/cmc-bg.svg);
  background-color: #fff;
  background-size: cover;
  background-repeat: repeat-y;
}

.cmc-nav {
  border-bottom: 4px solid #fff;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 10px 40px 0px rgba(225, 233, 255, 0.25);
  z-index: 1;
  position: relative;
}

.cmc-links {
  color: var(--CMC-FONT);
  font-family: var(--CMC-FF) !important;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
}

.cmc-links:hover {
  background: linear-gradient(to right,
      var(--PURPLE),
      var(--PURPLE),
      #4679b3,
      #4679b3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cmc-hero-container {
  padding: 110px 0;
}

.cmc-heading {
  font-size: 72px;
  line-height: 70px;
  z-index: 10;
}

.cmc-para {
  font-family: var(--CMC-FF) !important;
  width: 400px;
  margin-top: 30px !important;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  z-index: 10;
}

.title-gradient {
  background: linear-gradient(to right,
      var(--PURPLE),
      var(--PURPLE),
      var(--TURQUOISE),
      var(--TURQUOISE));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cmc-signin-btn {
  background: linear-gradient(#fffffff5, #fffffff5) padding-box,
    linear-gradient(to right,
      var(--PURPLE),
      var(--PURPLE),
      var(--PRIMARYBLUE),
      var(--PRIMARYBLUE)) border-box;
  padding: 15px 30px;
  border: 2px solid transparent;
  border-radius: 5px;
  font-size: 20px;
  font-family: var(--CMC-FF) !important;
  font-weight: 600;
  color: #4d4077;
}

.cmc-register-btn {
  background: linear-gradient(90deg, var(--PURPLE) 0%, #3d9bff 100%);
  --button-hover-color: linear-gradient(90deg, #3480d1 0%, #7d64ce 100%);
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  font-family: var(--CMC-FF) !important;
  color: #fff;
  font-weight: 600;
}

.cmc-signin-btn:hover {
  background: linear-gradient(90deg, #3d9bff25 0%, #9a7bff25 100%);
}

.cmc-register-btn:hover {
  background: var(--button-hover-color)
}

.book-img {
  margin-right: -100px;
  z-index: 1;
}

.child-img-container {
  margin-top: -200px;
}

.child-img {
  margin-right: -285px;
  z-index: 2;
  margin-bottom: 10px;
}

.light-green-container {
  padding: 30px 0;
  background-color: var(--LIGHTGREEN);
  border-radius: 30px;
  margin-top: -55px;
  z-index: 1;
}

.arrow-one {
  margin-left: 60px;
  margin-top: -60px;
}

.arrow-two {
  z-index: 2;
  margin-left: -475px;
  margin-bottom: -70px;
}

/* CMC Sign In */

.signin-container {
  background-color: var(--LIGHTGREEN);
  margin-top: 30px;
  border-radius: 20px;
  z-index: 2;
}

.btn-register {
  background-color: #4a938d;
  color: #fff;
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
}

.btn-register:hover {
  background-color: #3b7570;
}

.signin-heading {
  font-family: var(--CMC-FF) !important;
  font-size: 50px;
  font-weight: 700;
}

.signin-para {
  font-family: var(--CMC-FF) !important;
  font-size: 18px;
}

.gradient-line {
  width: 150px;
  height: 10px;
  border-radius: 10px;
  background: linear-gradient(to right, #4a938d, #ffa700);
}

.parent-img {
  background-image: url(../images/parentimg.svg);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-img{
  background-image: url(https://img.freepik.com/free-photo/portrait-pensive-young-girl-making-notes_171337-1646.jpg?t=st=1735307761~exp=1735311361~hmac=25f8497c043a3cf722bf0cf9ead68d4a3e7cdc90676504da07fc1d0d09976b26&w=996);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.student-img {
  background-image: url(../images/studentimg.svg);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.admin-img {
  background-image: url(../../../../assets//images/admin-card-img.png);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.teacher-img {
  background-image: url(../images/teacher.svg);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.button-container {
  padding: 20px 0px;
  background-color: #fff;
  width: 380px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.signin-btn-container {
  width: 300px;
  height: 45px;
  border-radius: 8px;
  background-color: #4a938d;
  border: none;
  color: #fff;
  font-size: 14px;
}

.signUp-mini-btn {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #4a938d;
  color: #fff;
  font-size: 12px;
}

.signin-btn-container:hover {
  background-color: #3b7570;
}

.signin-note {
  white-space: nowrap !important;
  margin-bottom: 4px;
}

.signin-note-inside {
  margin: 0px 16px;
  cursor: pointer;
}

.signin-note-inside:hover {
  color: rgb(71, 71, 249);
}

@media (max-width: 767px) {
  .cmc-logo {
    width: 200px !important;
  }

  .register-btn-container {
    padding-right: 0rem !important;
  }

  .cmc-heading {
    font-size: 50px;
    line-height: 55px;
  }

  .cmc-para {
    width: 300px;
  }

  .cmc-links {
    font-size: 12px;
  }

  .title-underline {
    width: 160px !important;
  }

  .book-img {
    display: none;
  }

  .child-img-container {
    padding-top: 175px;
    display: flex !important;
    justify-content: center !important;
  }

  .child-img {
    margin-right: 0;
  }

  .light-green-container {
    margin-top: 50px;
  }

  .group-img {
    width: 350px !important;
  }

  .arrow-one,
  .arrow-two {
    display: none;
  }

  /* CMC Sign In */

  .no-account-text {
    display: none;
  }

  .vector-one,
  .vector-two {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .book-img {
    margin-right: -45px;
    width: 150px !important;
    margin-top: 100px;
  }

  .child-img {
    width: 400px !important;
  }

  .child-img-container {
    margin-top: 0px;
    display: flex !important;
    justify-content: center !important;
  }

  .child-img {
    margin-right: 0px;
  }

  .light-green-container {
    margin-top: 30px;
  }

  .arrow-one {
    margin-top: -130px;
    margin-left: -80px;
  }

  .arrow-two {
    margin-left: -220px;
    margin-top: 240px;
  }

  /* CMC Sign In */

  .parent-img {
    background-image: url(../images/parentimg.svg);
    height: 174px;
    width: 261px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .student-img {
    background-image: url(../images/studentimg.svg);
    height: 174px;
    width: 261px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .button-container {
    padding: 20px 0px;
    background-color: #fff;
    width: 261px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .signin-btn-container {
    width: 220px;
    height: 45px;
    border-radius: 8px;
    background-color: #4a938d;
    border: none;
    color: #fff;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1440px) {
  .cmc-landing {
    /* height: 100vh; */
  }
}