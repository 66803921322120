.container-drillchart {
    border: 1px solid #ccc;
    height: 300px;
    max-width: 600px;
    padding: 10px;
    margin: 50px auto;
    background-color: #D9DBF1;
    border-radius: 5px;
    overflow-y: auto;
}

.content-drillchart {
    margin-left: 20px;
    margin-top: 10px;
    /* Add gap at the top */
    margin-bottom: 10px;
}

.top-right-buttons {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
}

/* Figure Chart */

.figure-chart-container {
    border: 2px solid #edeef7;
    border-radius: 5px;
    padding: 10px 5px;
}

.figure-chart-text {
    font-size: 16px !important;
}

.multiBar {
    height: 70vh !important;
    font-family: "Poppins", sans-serif !important;
}

@media (max-width:768px) {
    .dropdownToggle {
        width: 75vw !important;
    }

    .groupedBarchart {
        display: none;
    }
}

.progress {
    margin: 6px 0px 16px 0px;
}