.landing-card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    background-color: #fff;
    border-bottom-left-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem;
}

.landing-card-img {
    border-top-right-radius: 0.42rem;
    border-top-left-radius: 0.42rem;
}

.landing-topic {
    font-family: var(--primary-font);
    font-weight: 600;
}

.landing-para {
    font-family: var(--secondary-font);
}

.landing-btn {
    font-family: var(--primary-font);
    width: 100%;
    padding: 10px;
    color: var(--main-text-color);
    background-color: #2D174C;
}

.landing-btn:hover {
    font-family: var(--primary-font);
    width: 100%;
    padding: 10px;
    color: var(--main-text-color);
    background-color: #201036;
}

.underline-container {
    height: 8px;
    background-color: #1A8FE3;
    border-radius: 30px;
    margin-top: 15px;
}

.landing-para-underline {
    width: 120px;
    height: 5px;
}

.landing-bg {
    height: 100vh;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.logo-img {
    max-width: 200px;
    max-height: 50px;
}

.landing-card {
    border: 0;
    width: 90%;
}

.landing-footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

@media (min-width: 576px) and (max-width: 1024) {
    .landing-bg {
        background-image: url("../images/home-bg.png");
        height: 120vh;
    }
}

/*desktops - higher resolution*/
@media (min-width: 1281px) {
    .landing-bg {
        background-image: url("../images/home-bg.png");
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
    .landing-bg {
        background-image: url("../images/home-bg.png");
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
    .landing-bg {
        background-image: url("../images/home-bg-mobile.png");
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .landing-bg {
        background-image: url("../images/home-bg-mobile.png");
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
    .landing-bg {
        background-image: url("../images/home-bg-mobile.png");
        height: 180vh;
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
    .landing-bg {
        background-image: url("../images/home-bg-mobile.png");
        height: 180vh;
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

@media (max-width: 319px) {
    .landing-bg {
        background-image: url("../images/home-bg-mobile.png");
        height: 180vh;
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}