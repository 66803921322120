.rbc-header {}

.rbc-event {
    width: auto;
    /* Adjust the width of the box as needed */
    height: 10px;
    /* Adjust the height of the box as needed */
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 1px;
    color: white;
    font-size: 9px;
    cursor: pointer;
}

.rbc-background-event {
    padding: 0px !important;
    border-radius: 0px !important;
    border: none !important;
    background-color: transparent !important;
}

.rbc-time-slot {
    background-color: #eaf3fa;
    color: #74a4c3;
    border-color: aqua !important;
}

.rbc-event-label {
    display: none !important;
}

.rbc-events-container {
    width: 100% !important;
}

.rbc-timeslot-group {}

.event-box {
    width: 50px;
    /* Adjust the width of the box as needed */
    height: 30px;
    /* Adjust the height of the box as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    cursor: pointer;
}

.custom-event-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-event-wrapper>* {
    margin-right: 5px;
    /* Adjust margin between events as needed */
}

.rbc-toolbar .rbc-toolbar-label {
    font-size: 13px;
}

.rbc-show-more {
    font-size: 8.5px;
}