.messages {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
}
.status_bar{
    height: 30px;
    margin-top: '-20px';
    max-width: "100%";
    background-color: #fafafa;
    border: none;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior:smooth;
}
.status_bar::-webkit-scrollbar{
    display: none;
}

.chat-bg {
    background-image: url("../../../../assets/images/chatbg.png");
}

.user-remove-btn {
    background-color: #FDEDEE !important;
    color: #F12E2D !important;
    text-transform: unset !important;
    letter-spacing: -0.2px !important;
    font-weight: bold !important;
}

.add-user-btn {
    background-color: #F0F0FF !important;
    color: #7779CF !important;
    text-transform: unset !important;
    letter-spacing: -0.2px !important;
    font-weight: bold !important;
}

.add-remove-members {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 11px;
}

.chat-members-container {
    border: 2px solid #EDEFF7;
    border-radius: 5px;
}