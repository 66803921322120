.filterCardContainer {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #d1d1d1 transparent;
  /* For Firefox */
}

.filterCardContainer::-webkit-scrollbar {
  height: 8px;
  /* Horizontal scrollbar height */
}

.filterCardContainer::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 4px;
}

.filterCardContainer::-webkit-scrollbar-track {
  background: transparent;
}

.filterAccordionSummary {
  margin: 0px !important;
}

@media (max-width: 1150px) {
  .css-j0ozid {
    display: flex !important;
    flex-direction: column !important;
    align-items: start !important;
  }
}