.fraction {
    display: inline-block;
    vertical-align: middle;
}

.numerator {
    display: block;
}

.denominator {
    display: block;
    border-top: 1px solid black;
}