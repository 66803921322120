#kt_wrapper {
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 30px;
    margin-top: 70px;
    margin-right: -20px;
}

@media (max-width: 768px) {
    #kt_wrapper {
        padding-left: 0px;
        padding-top: 0px;
        margin-left: 0px;
        /* margin-top: 0px; */
        margin-right: 0px;
    }
}

/* #footer{
    padding-Left: "-30px";
    margin-Left: "-30px !important";
    z-Index: -9;
} */
#footer {
    padding-Left: 0px;
    margin-Left: -30px;
    z-Index: 25;
    /* margin-top: 0vh; */
}

@media (max-width: 768px) {
    #footer {
        padding-Left: 0px;
        margin-Left: 0px;
        z-Index: 25;
    }
}