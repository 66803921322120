.progress_bar{
    height: 140px;
    margin-top: '-20px';
    max-width: "100%";
    background-color: #fafafa;
    border: none;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior:smooth;
}
.progress_bar::-webkit-scrollbar{
    display: none;
}

.user-list-row {
    display: flex;
    flex-direction: row;
}

@media (max-width: 767px) {
    .user-list-row {
        flex-direction: column-reverse;
    }
}