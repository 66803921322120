@import url("https://fonts.googleapis.com/css2?family=Boogaloo&family=DM+Sans:ital@0;1&family=Poppins:wght@300;400;500;600&display=swap");

/* RESET */

img {
  display: block;
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

:root {
  /* FONTS */
  --FF: "DM Sans", sans-serif;
  --FF-MAINHEADING: "Boogaloo", cursive;
  --FF-SECTIONHEADING: "Poppins", sans-serif;

  --FS-MAINHEADING: 12rem;
  --FS-SECTIONTITLE: 5.75rem;
  /* --FS-SUBHEADING: 1.125rem;
  --FS-DETAILTEXT: 1.125rem;
  --FS-SMALLTEXT: 1.125rem;
  --FS-SMALLESTTEXT: 1.125rem; */

  /* COLORS */
  --BGCOLOR: #fff;
  --SECTION-BGCOLOR: #edeef7;
  --BUTTON-COLOR: linear-gradient(to right,
      var(--DARKBLUE),
      var(--PRIMARYBLUE));
  --FONT-COLOR: #2c3440;
  --PRIMARYBLUE: #3d9bff;
  --DARKBLUE: #187de4;
  --LIGHTBLUE: #edeef7;
  --NAV-BGCOLOR: #fff;
  --MAIN-CLR: #0B1360;
  --BLK-CLR: #000000;

  /* BORDERS */
  --BORDER: 3px solid var(--LIGHTBLUE);
  --BORDER-RADIUS: 10px;
  --BUTTON-BORDER-RADIUS: 50px;
}

/* COMMON STYLES */

html {
  scroll-behavior: smooth;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.bg-landing-page {
  background-color: #fff;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  /* padding-top: 10px; */
  /* padding-bottom: 60px; */
}

.colPadding {
  padding: 0px;
}

.section-container {
  /* padding: 5rem 0; */
}

.footer-container {
  background-color: #EDEEF7 !important;
  padding-top: 5rem;
  padding-bottom: 1rem;
}

.light-blue-container {
  background-color: var(--LIGHTBLUE);
}

.edsider-section-title {
  font-family: var(--FF-SECTIONHEADING);
  font-size: var(--FS-SECTIONTITLE);
  letter-spacing: -0.3rem;
  color: var(--BLK-CLR);
  font-weight: 600;
  padding-top: 50px;
  padding-bottom: 20px;
}

.white-section-title {
  color: #fff;
}

/* NAVIGATION SECTION */

.navigation-bg {
  background-color: #FFF;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.navig-links {
  color: var(--FONT-COLOR);
  margin-left: 1.5rem;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--FONT-COLOR);
}

.navigation-links {
  font-family: var(--FF-SECTIONHEADING);
  color: var(--FONT-COLOR);
  font-weight: 400;
  font-size: 1.2rem;
  font-style: normal;
  letter-spacing: -0.05rem;
}

.useful-links {
  white-space: nowrap;
  padding-right: 16rem;
}

.navbar-collapse {
  flex-grow: 0;
}

.signin-btn {
  --button-default-color: linear-gradient(90deg, #3F11A2 0%, #3D9BFF 100%);
  --button-hover-color: linear-gradient(90deg, #3d9bff 0%, #187de4 100%);

  background: var(--button-default-color);
  border-radius: 50rem;
  font-family: var(--FF-SECTIONHEADING);
  color: #fff;
  border: none;
  padding: 10px 20px;
  background-color: #fff;
  white-space: nowrap;
}

.register-btn,
.getstarted-btn {
  --button-default-color: linear-gradient(90deg, #3F11A2 0%, #3D9BFF 100%);
  --button-hover-color: linear-gradient(90deg, #3d9bff 0%, #187de4 100%);

  background: var(--button-default-color);
  border-radius: 50rem;
  padding: 18px 30px;
  border: none;
  font-family: var(--FF-SECTIONHEADING);
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  width: 20rem !important;
  transition: background-color 0.5s ease-in-out;
  margin-top: 64px;
  margin-bottom: 20px;
  padding: 20px 40px;
}

.btn2 {
  width: 15rem;
}

.register-btn:hover,
.getstarted-btn:hover {
  background: var(--button-hover-color);
}

.back-btn {
  background-color: #5458AF;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  color: #FFF !important;
  width: 75px;
  display: flex;
  justify-content: center;
  /* margin-left: 30px; */
}

.back-btn:hover {
  background-color: #2F316B;
}

#cmc .back-btn {
  background-color: var(--maincolor-cmc);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  color: #FFF !important;
  width: 75px;
  display: flex;
  justify-content: center;
  /* margin-left: 30px; */
}

#cmc .back-btn:hover {
  background-color: #046FA4;
}

/* HERO SECTION */
.home {
  height: 90vh;
  margin-top: 2vh;
}

.support {
  height: fit-content;
}

@media(max-width:768px) {
  .support {
    height: 100vh;
  }
}

.chooseEdsider {
  height: fit-content;
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.hero-section-container {
  margin-top: 50px;
}

.main-heading {
  color: var(--MAIN-CLR);
  font-family: var(--FF-MAINHEADING) !important;
  font-size: 8rem !important;
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: -0.1rem;
  /* line-height: 10rem; */
  margin-bottom: 0px !important;
}

@media (min-width:1800px) {
  .heroMainSec {
    margin-top: 20vh;
    padding: 0px !important;
    max-width: 1500px !important;
  }
}

@media (min-width:768px) and (max-width:1400px) {
  .heroMainSec {
    margin-top: 20vh;
  }
}

.sub-heading {
  color: var(--BLK-CLR);
  font-family: var(--FF-SECTIONHEADING) !important;
  font-size: 2.3rem;
  font-weight: 400;
  letter-spacing: -0.125rem;
  padding-bottom: 8px;
  margin: 0px !important;
}

.paragraph {
  color: #8290d8;
  font-family: var(--FF) !important;
  letter-spacing: -0.075rem;
  font-size: 2.5rem;
}

@media (max-width:1200px) {
  .paragraph {
    font-size: 1.5rem;
  }
}

.lang-text {
  font-size: 1.3rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-image {
  max-width: auto;
  max-height: 60vh;
  position: absolute;
}

/* IMAGE CAROUSAL */

.landing-carousal-img {
  width: 100%;
  display: flex;
}

/* SUPPORT SECTION */

.support-img {
  width: 14rem;
  height: auto;
}

.left-card-container {
  white-space: nowrap;
}

.image-container-end {
  display: flex;
  justify-content: flex-end;
}

.text-container {
  color: var(--FONT-COLOR);
  font-family: var(--FF-SECTIONHEADING);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0875rem;
}

.support-row-title {
  color: var(--FONT-COLOR);
  font-family: var(--FF-SECTIONHEADING);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.075rem;
}

.support-row-content {
  font-family: var(--FF) !important;
  font-size: 1.2rem;
  color: var(--FONT-COLOR);
  letter-spacing: -0.045rem;
}

.support-container {
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
  background-color: #fff;
  height: fit-content;
  margin: 5px 0;
  padding: 10px;
}

.support-card {
  padding: 10px 20px;
}

.coming-soon-text {
  position: absolute;
  font-style: italic;
  transform-origin: top left;
  color: red;
  transform: rotate(-30deg);
  font-weight: 500;
  padding-top: 30px;
  z-index: 1;
  opacity: 0;
  animation: fade-in-out 2s ease-in-out infinite;
}

@keyframes fade-in-out {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.checkIcon {
  color: var(--DARKBLUE);
  margin-right: 5px;
}

/* GET STARTED SECTION */

.get-started-title {
  font-family: var(--FF-SECTIONHEADING);
  color: var(--FONT-COLOR);
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -0.0875rem;
}

.contact-text {
  color: #a1a1a1;
  font-family: var(--FF-SECTIONHEADING);
  font-size: 1.375rem;
  font-style: italic;
  margin-left: 25px;
  font-weight: 300;
}

.contact-icons {
  margin-left: 10px;
}

/* ABOUT US SECTION */

.bg-support-section {
  background-image: url("../images/about-bg.png");
  background-size: cover;
  height: 650px;
}

.about-para {
  font-family: var(--FF) !important;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.045rem;
  color: #000000;
}

.card-container {
  height: fit-content;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
  padding: 0 4rem;
  background-color: #fff;
  padding-bottom: 10px;
  margin-top: 30px;
}

.about-card-title {
  font-family: var(--FF-SECTIONHEADING);
  font-weight: 600;
  font-size: 3rem;
  letter-spacing: -0.1rem;
  padding-top: 40px;
  color: var(--FONT-COLOR);
}

.about-card-text {
  font-family: var(--FF) !important;
  font-size: 1.4rem;
  letter-spacing: -0.045rem;
  color: var(--FONT-COLOR);
}

@media (max-width:768px) {
  .about-card-title {
    padding-top: 20px;
  }
}

/* WHY CHOOSE EDSIDER */

.edsider-card {
  background-color: #fff;
  border-radius: var(--BORDER-RADIUS);
}

.edsider-img-container {
  padding: 40px 0;
}

.edsider-card-title {
  color: var(--FONT-COLOR);
  font-family: var(--FF-SECTIONHEADING);
  font-weight: 500;
  letter-spacing: -0.08rem;
  padding-bottom: 20px;
  font-size: 2.1rem;
}

.edsider-card-para {
  width: 20rem;
  font-size: 1.3rem;
  font-family: var(--FF) !important;
  font-weight: 400;
  letter-spacing: -0.045rem;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .edsider-card {
    min-height: 450px;
  }
}

/* TABS SECTION */

.tabs-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tab-heading {
  font-family: var(--FF-SECTIONHEADING);
  font-weight: 500;
  font-size: 1.125rem;
}

.tab-content-row {
  padding: 20px;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
  min-height: 220px;
}

.tab-row-title {
  font-family: var(--FF-SECTIONHEADING);
  font-size: 1.625rem;
  color: var(--FONT-COLOR);
  font-weight: 500;
  letter-spacing: -0.08125rem;
  padding-bottom: 5px;
  border-bottom: gray 1px solid;
}

.tab-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-row-content {
  font-family: var(--FF) !important;
  color: var(--FONT-COLOR);
  letter-spacing: -0.045rem;
  font-weight: 400;
  font-size: 1.3rem;
}


@media (max-width: 768px) {
  .edsider-img-container {
    padding: 20px 0;
  }

  .edsiderImage {
    width: 80px;
  }
}

/* MODEL PAPERS SECTION */

.papers-row {
  padding: 30px 30px;
}

.papers-container {
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
  background-color: #fff;
  height: 10rem;
  margin: 5px 0;
  padding: 10px;
}

.papers-para {
  background-color: #fff;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
}

.papers-text {
  font-family: var(--FF) !important;
  font-size: 1.5rem;
  letter-spacing: -0.045rem;
  padding-left: 30px;
  line-height: normal;
}

.check-icon {
  width: 15px !important;
}

/* SCHOLARSHIP SECTION */

.bg-scholarship-section {
  background-image: url("../images/scholarship.png");
  background-size: cover;
}

.schol-card-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17rem;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
}

.schol-card-title {
  color: var(--FONT-COLOR);
  font-size: 2rem;
}

/* TESTIMONIALS SECTION */

.arrow-icons {
  padding-top: 50px;
  padding-bottom: 20px;
}

.arrow-icons-left,
.arrow-icons-right {
  width: 60px;
  height: 60px;
  border-radius: var(--BORDER-RADIUS);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow-icons-left {
  background-color: #fff;
  margin-right: 20px;
}

.arrow-icons-right {
  background: var(--button-gradient,
      linear-gradient(90deg, #187de4 0%, #3d9bff 100%));
}

.testimonial-card {
  background-color: #fff;
  height: 18rem;
  padding: 40px 40px;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
}

.testimonial-title {
  font-family: var(--FF-SECTIONHEADING);
  color: var(--FONT-COLOR);
  font-size: 1.75rem;
  letter-spacing: -0.0875rem;
  font-weight: 500;
  /* padding-left: 20px; */
  margin: 0;
}

.testimonial-text {
  font-family: var(--FF) !important;
  color: var(--FONT-COLOR);
  font-size: 1.2rem;
  letter-spacing: -0.045rem;
  padding-top: 30px;
}

/* FOOTER */

.bg-cyan-section {
  background-color: #EDEEF7;
}

.footer-text {
  font-family: var(--FF) !important;
  font-size: 1.125rem;
  letter-spacing: -0.05625rem;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 40px;
}

.follow-text {
  font-family: var(--FF) !important;
  font-size: 1.125rem;
  letter-spacing: -0.05625rem;
  font-weight: 500;
  margin-bottom: 8px !important;
}

.social-icons {
  margin-left: 1px;
}

.fb-icon,
.twitter-icon,
.insta-icon,
.mail-icon {
  padding-right: 8px;
  cursor: pointer;
}

.footer-title {
  font-family: var(--FF) !important;
  font-size: 1.125rem;
  letter-spacing: -0.045rem;
  color: var(--FONT-COLOR);
  font-weight: 600;
  margin-left: 12px;
}

/* @media (min-width: 768px) {
.general-title {
  margin-left: 26px;
}
} */

.footer-content {
  color: var(--FONT-COLOR);
  font-size: 1rem;
  font-weight: 300;
  font-family: var(--FF-SECTIONHEADING);
  letter-spacing: -0.04375rem;
  cursor: pointer;
}

.footer-links {
  color: var(--FONT-COLOR);
}

.copyright-container {
  border-top: 1px solid var(--FONT-COLOR);
  font-family: var(--FF) !important;
  font-size: 1rem;
  padding: 10px 0;
  color: var(--FONT-COLOR);
  letter-spacing: -0.04375rem;
}

.policy-content {
  color: var(--FONT-COLOR);
}

/* MEDIA QUERIES */

@media (max-width: 420px) {
  .main-heading {
    font-size: 5rem !important;
  }

  .hero-section-container {
    margin-top: 20px;
  }

  .edsider-section-title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .tab-img-container {
    width: 100px;
    margin-bottom: 10px;
  }
}

@media (min-width: 421px) and (max-width: 576px) {

  .hero-section-container {
    margin-top: 20px;
  }

  .check-container {
    margin-left: 12px;
  }

  .navigation-list {
    display: flex;
  }

  .first-link-item {
    padding-top: 30px;
  }

  .main-heading {
    font-size: 6rem !important;
    z-index: 5;
  }

  .edsider-section-title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .action-links {
    margin-left: -5px;
  }

  .signin-btn,
  .register-btn {
    /* margin-left: 1.5rem; */
    color: var(--PRIMARYBLUE);
  }

  .register-btn {
    --button-default-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    --button-hover-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    padding: 0px 0px;
    text-align: start;
    margin-left: 24px;
    color: var(--PRIMARYBLUE);
  }

  .image-container {
    padding-top: 10px;
  }

  .support-left-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-card-container {
    display: flex;
    justify-content: center;
  }

  .support-title {
    padding-top: 0;
  }

  .support-container {
    height: 17rem;
  }

  .support-check {
    padding-bottom: 10px;
  }

  .text-container {
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
  }

  .card-container {
    margin-bottom: 2rem;
    height: 18rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }

  .bg-about-section {
    height: 780px;
  }

  .about-card-title {
    padding-top: 0;
    font-size: 2rem;
  }

  .edsider-card {
    margin-bottom: 20px;
  }

  .edsider-card-para {
    width: 20rem;
  }

  .tab-image-container {
    padding: 30px 0;
  }

  .tab-img-container {
    width: 30%;
    padding-bottom: 20px;
  }

  .papers-container {
    height: 15rem;
  }

  .schol-card-container {
    margin-bottom: 2rem;
    height: 10rem;
  }

  .arrow-icons-left {
    margin-right: 10px;
  }

  .footer-title {
    padding-top: 30px;
    margin-left: 0;
  }

  .footer-links {
    margin-left: 0;
  }

  .general-links {
    margin-left: -12px;
  }

  .f-action-links {
    margin-left: -13px;
  }

  .copyright-items {
    display: flex;
    justify-content: center;
  }

  .policy-content {
    display: flex !important;
    justify-content: center !important;
  }

}

@media (max-width:768px) {
  .tab-img-container {
    width: 120px;
  }

  .footer-title{
    margin-top: 16px !important;
  }

  .footer-text {
    padding-bottom: 20px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .main-heading {
    font-size: 8rem !important;
  }
}


@media (min-width: 1101px) and (max-width: 1200px) {
  .main-heading {
    font-size: 7rem !important;
  }
}

@media (min-width: 576px) and (max-width: 1100px) {
  .main-heading {
    font-size: 6rem !important;
    z-index: 5;
  }

  .action-links {
    margin-left: -5px;
  }

  .first-link-item {
    /* padding-top: 30px; */
  }

  .signin-btn,
  .register-btn {
    /* margin-left: 1.5rem; */
    color: var(--PRIMARYBLUE);
  }

  .register-btn {
    --button-default-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    --button-hover-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    padding: 0px 0px;
    text-align: start;
    margin-left: 24px;
    color: var(--PRIMARYBLUE);
  }

  .card-container {
    padding: 0 2rem;
  }

  .about-card-title {
    padding-top: 2rem;
  }

  .schol-card-container {
    /* padding-bottom: 2rem; */
  }

  .edsider-card-para {
    width: 15rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {

  .signin-btn,
  .register-btn {
    /* margin-left: 1.5rem; */
    color: var(--PRIMARYBLUE);
  }

  .register-btn {
    --button-default-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    --button-hover-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    padding: 0px 0px;
    text-align: start;
    margin-left: 24px;
    color: var(--PRIMARYBLUE);
  }

  .schol-card-container {
    height: 10rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .useful-links {
    padding-left: 1rem;
  }
}

@media (max-width: 1440px) {
  .useful-links {
    padding-right: 6rem;
  }
}

.edaMain1 {
  z-index: 3;
}

.edaMain2 {
  z-index: 2;
}

.edaMain3 {
  z-index: 1;
}


.image-stack {
  position: relative;
  width: 100%;
}

.image-stack__item--top {
  position: absolute;
  right: 0;
  top: 10px;
  width: 60%;
  z-index: 0;
}

.image-stack__item--bottom {
  padding-top: 0px;
  padding-right: 20%;
  width: 80%;
}

.aboutImgs {
  width: auto;
  height: 250px;
}

@media (min-width: 910px) and (max-width:1300px) {
  .support-card {
    padding: 0px;
  }

  .support-row-content {
    font-size: 1rem;
  }
}

@media (max-width:768px) {
  .aboutImgs {
    display: none;
  }
}

.welcome-topic {
  color: #5458AF;
  font-family: 'poppins';
  font-weight: 500;
  font-size: 28px;
  position: relative;
  z-index: 1;
  line-height: 24px;
}

/* @media (max-width: 992px ) {
  .displayWeb {
    display: none;
  }
}

@media (min-width: 992px ) {
  .displayMobile {
    display: none;
  }
} */


/* Hero.css */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes bounce-in {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-50px);
  }

  60% {
    /* transform: translateY(-25px); */
  }
}

.main-heading {
  width: 9ch;
  /* 9 characters in "Study Zone" */
  animation: typing 3s steps(9, end) 0.5s 1 normal both;
  /* Steps based on number of characters */
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 8ch;
  }

  /* Matches the number of characters */
}


.sub-heading {
  animation: fadeIn 1s ease-in-out forwards, layDownGetUp 1s ease-in-out forwards;
}

@keyframes layDownGetUp {
  0% {
    transform: rotateX(90deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

@media (min-width: 769px) {
  .sub-heading {
    white-space: nowrap;
  }

  .paragraph {
    white-space: nowrap;
  }

  .mobileHeroImg {
    display: none;
  }
}

@media (max-width: 768px) {
  .heroImgs {
    display: none;
  }

  .centered-image {
    position: relative;
  }
}

.paragraph {
  animation: fadeIn 1s ease-in-out forwards, layDownGetUp 1s ease-in-out forwards;
}

.getstarted-btn {
  animation: bounce-in 1.5s;
}

.mobileHero {
  display: flex;
  justify-content: center;
}

.mobileHeroImg {
  height: 25vh;
  width: auto;
}

.image-container .centered-image {
  opacity: 0;
}

.image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.image-container .centered-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
}

.image-container .edaMain1 {
  animation: slideInFade 9s ease-in-out infinite;
}

.image-container .edaMain2 {
  animation: slideInFade 9s ease-in-out infinite;
  animation-delay: 3s;
}

.image-container .edaMain3 {
  animation: slideInFade 9s ease-in-out infinite;
  animation-delay: 6s;
}

@keyframes slideInFade {

  0%,
  100% {
    opacity: 0;
    transform: translateX(100%);
  }

  10%,
  30% {
    opacity: 1;
    transform: translateX(0);
  }

  40%,
  90% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Support.css */
.supTitle {
  animation: fadeInFromLeft 1s ease-in-out forwards;
}

@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fallAndBounce {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  60% {
    transform: translateY(20%);
  }

  80% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(0);
  }
}

.falling-image1 {
  animation: fallAndBounce 1.5s ease-out forwards;
}

@media (max-width: 910px) {

  .falling-image1 {
    margin-bottom: 15%;
    visibility: hidden
  }

  .image-stack__item--top {
    width: 100%;
  }

  .support-card {
    padding: 0px 5px;
  }
}


/* Initial state */
.support-container {
  opacity: 0;
  transform: translateX(200px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

/* Animate class for elements that enter the viewport */
.animate {
  opacity: 1;
  transform: translateX(0);
}

/* Animation delays for each support container */
.support-container.animation-delay-1 {
  transition-delay: 0.6s;
}

.support-container.animation-delay-2 {
  transition-delay: 1.2s;
}

.support-container.animation-delay-3 {
  transition-delay: 1.8s;
}


.support-row-title {
  font-weight: bold;
  /* font-size: 1.2rem; */
  margin-bottom: 0.5rem;
}

.support-row-content {
  /* font-size: 1rem; */
  line-height: 1.4;
}

/* ChooseEdsider.css */
@keyframes typingEffect {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .titlechooseEd {
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  animation: slideInLeft 1s ease-out forwards;
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: black;
  }
} */

/* Initial state */
.edsider-card {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* Animate class for elements that enter the viewport */
.animate {
  opacity: 1;
  transform: translateY(0);
}


@media (max-width:768px) {
  .edsider-card {
    margin: 10px;
  }
}

/* About.css */
@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes layEffect {
  from {
    opacity: 0;
    transform: rotate(90deg) translateY(-100%);
  }

  to {
    opacity: 1;
    transform: rotate(0deg) translateY(0);
  }
}

@keyframes standEffect {
  from {
    opacity: 0;
    transform: rotate(-90deg) translateY(-100%);
  }

  to {
    opacity: 1;
    transform: rotate(0deg) translateY(0);
  }
}

.about-title {
  opacity: 0;
  transform: translateY(-100%);
}

.about-para {
  opacity: 0;
}

.about-card {
  opacity: 0;
}

.aboutImgs {
  opacity: 0;
}

.animate {
  opacity: 1;
}

.about-title.animate {
  animation: slideInUp 1s ease-out forwards;
}

.about-para.animate {
  animation: slideInDown 1s ease-out forwards;
}

.left-to-right.animate {
  animation: slideInLeft 1s ease-out forwards;
}

.right-to-left.animate {
  animation: slideInRight 1s ease-out forwards;
}

.lay-effect.animate {
  animation: layEffect 1.5s ease-out forwards;
}

.stand-effect.animate {
  animation: standEffect 1.5s ease-out forwards;
}

/* Tabs.css */
@keyframes topToButtom {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttomToTop {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.up-to-down {
  opacity: 0;
  transform: translateY(-100%);
}

.down-to-up {
  opacity: 0;
  transform: translateY(100%);
}

.animate {
  opacity: 1;
}

.up-to-down.animate {
  animation: topToButtom 1s ease-out forwards;
}

.down-to-up.animate {
  animation: buttomToTop 1s ease-out forwards;
}