.DragDrop {
    font-family: Arial, sans-serif;
}

.customization-form {
    margin-bottom: 20px;
}

.customization-form label {
    display: block;
    margin-bottom: 10px;
}

#dragContainer span {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #ddd;
    border: 1px solid #ccc;
    cursor: pointer;
}

.drop-box {
    display: inline-block;
    width: 100px;
    height: 20px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
}