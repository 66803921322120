@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css2?family=Boogaloo&family=DM+Sans:ital@0;1&family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500;600;700;900&family=Poppins:wght@200&display=swap);
.landing-card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    background-color: #fff;
    border-bottom-left-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem;
}

.landing-card-img {
    border-top-right-radius: 0.42rem;
    border-top-left-radius: 0.42rem;
}

.landing-topic {
    font-family: var(--primary-font);
    font-weight: 600;
}

.landing-para {
    font-family: var(--secondary-font);
}

.landing-btn {
    font-family: var(--primary-font);
    width: 100%;
    padding: 10px;
    color: var(--main-text-color);
    background-color: #2D174C;
}

.landing-btn:hover {
    font-family: var(--primary-font);
    width: 100%;
    padding: 10px;
    color: var(--main-text-color);
    background-color: #201036;
}

.underline-container {
    height: 8px;
    background-color: #1A8FE3;
    border-radius: 30px;
    margin-top: 15px;
}

.landing-para-underline {
    width: 120px;
    height: 5px;
}

.landing-bg {
    height: 100vh;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.logo-img {
    max-width: 200px;
    max-height: 50px;
}

.landing-card {
    border: 0;
    width: 90%;
}

.landing-footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

@media (min-width: 576px) and (max-width: 1024) {
    .landing-bg {
        background-image: url(/static/media/home-bg.f633afd9.png);
        height: 120vh;
    }
}

/*desktops - higher resolution*/
@media (min-width: 1281px) {
    .landing-bg {
        background-image: url(/static/media/home-bg.f633afd9.png);
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
    .landing-bg {
        background-image: url(/static/media/home-bg.f633afd9.png);
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
    .landing-bg {
        background-image: url(/static/media/home-bg-mobile.d5118a8b.png);
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .landing-bg {
        background-image: url(/static/media/home-bg-mobile.d5118a8b.png);
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
    .landing-bg {
        background-image: url(/static/media/home-bg-mobile.d5118a8b.png);
        height: 180vh;
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
    .landing-bg {
        background-image: url(/static/media/home-bg-mobile.d5118a8b.png);
        height: 180vh;
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}

@media (max-width: 319px) {
    .landing-bg {
        background-image: url(/static/media/home-bg-mobile.d5118a8b.png);
        height: 180vh;
    }

    .landing-topic,
    .landing-para {
        color: var(--main-text-color);
    }
}
.login-bg{
    background: var(--bgcolor);
    height:100vh;
    
}
.login-card-title{
    font-family: var(--secondary-font);
    color: var(--textcolor);
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}
.login-card{
    border-radius: 10px;
    border:0;
    margin-right: auto;
    margin-left: auto;
}
.login-label{
    font-family: var(--secondary-font);
    color: var(--textcolor);
}
.login-input{
    color: #6e6b7b;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    padding: 14px 16px;
}
.login-input:focus, .login-input:active, .login-input:focus-within {
    outline: 0;
    box-shadow: none;
    border-color: var(--maincolor);
}
.login-btn{
    font-family: var(--secondary-font);
    height: 45px;
    font-weight: 500;
    font-size: 15px;
}
.login-btn:hover{
    box-shadow: 0 8px 25px -8px var(--maincolor);
}
.login-text{
    font-family: 'Montserrat';
    font-weight: 500;
}
.login-sub-text{
    color:var(--textcolor);
}
.microsoft-btn{
    font-family: 'Montserrat';
    height: 45px;
    font-weight: 500;
    font-size: 15px;
    width:100%;
}
.x-icon {
    height: 20px;
    width:  20px;
    top: .125em;
    right:0;
    /* position: relative; */
  }

/*desktops - higher resolution*/
@media (min-width: 1281px){
    .login-card-body{
        padding-left: 80px;
        padding-right: 80px;
    }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
    .login-card-body{
        padding-left: 50px;
        padding-right: 50px;
    }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px){
    .login-card-body{
        padding-left: 50px;
        padding-right: 50px;
    }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .login-card-body{
        padding-left: 50px;
        padding-right: 50px;
    }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px){
    .login-card-body{
        padding-left: 20px;
        padding-right: 20px;
    }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px){

}

.swal-text,
.swal-title {
  text-align: center;
  color: #2c2e3a;
}

.swal-modal {
  background-color: #fff;
}

.swal-icon--custom {
  width: 150px;
  height: 150px;
}

.swal-icon--success__hide-corners {
  background: none !important;
}
.swal-icon--success:before,
.swal-icon--success:after {
  background: none !important;
}

.container-terms{
    margin: 50px auto 20px auto;
    width: 80%;
    padding: 30px 50px 50px 50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 20px;
    text-align: justify;
}

hr{
    height: 1px;
    background-color: #ccc;
    border: none;
}

.buttons{
    margin-top: 10px;
    text-align: center;
}

:root {
    --primary-font:'Poppins';
    --secondary-font:'Montserrat';
    --main-text-color: #ffffff;
    --secondary-text-color: #3F4254;
    --bgcolor: #E0EFFF;
    --textcolor: #5E5873;
    --table-theme-color: #B5B5C3;
    --maincolor:#5458AF;
    --secondary-color:#404386;
    --menu-icon-hover: #41448d;
    --cardprimarycolor: #5458af;
    --selected-card-color: #2F316B;
    --hover-card-color: #41448d;
    --sidetopnav-color:#217cd6;
    --topnav-color:#ffffff;
    --footer-color:#ffffff;
    --body-color:#eef0f8;
    --actionbtn-color: #A2A5B5;
    --actionbtn-bg-color: #F6F8FA;

    --maincolor-cmc:#2980B9;
    --secondary-cmc:#005884;
    --menu-icon-hover-cmc: #046FA4;
    --cardprimarycolor-cmc: #2980B9;
    --selected-card-color-cmc: #005884;
    --hover-card-color-cmc: #016799;

  }

.font{
    font-weight: bold;
    font-size: small;
}
body{
  font-family: var(--primary-font);
  background-color:  var(--body-color);
}

/* --------------old css---------------- */
 .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  




#kt_wrapper {
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 30px;
    margin-top: 70px;
    margin-right: -20px;
}

@media (max-width: 768px) {
    #kt_wrapper {
        padding-left: 0px;
        padding-top: 0px;
        margin-left: 0px;
        /* margin-top: 0px; */
        margin-right: 0px;
    }
}

/* #footer{
    padding-Left: "-30px";
    margin-Left: "-30px !important";
    z-Index: -9;
} */
#footer {
    padding-Left: 0px;
    margin-Left: -30px;
    z-Index: 25;
    /* margin-top: 0vh; */
}

@media (max-width: 768px) {
    #footer {
        padding-Left: 0px;
        margin-Left: 0px;
        z-Index: 25;
    }
}
/* Sidebar.css */

.sidebar {
    height: 100%;
    width: 50px;
    position: fixed;
    top: 50px;
    left: 0;
    background-image: linear-gradient(#2D1366, #2A73C1);
    padding-top: 20px;
    z-index: 20;
    transition: width 0.2s;
    overflow: hidden;
}

#cmc .sidebar {
    height: 100%;
    width: 50px;
    position: fixed;
    top: 50px;
    left: 0;
    background-color: var(--maincolor-cmc);
    padding-top: 20px;
    z-index: 20;
    transition: width 0.2s;
    overflow: hidden;
}

.sidebar.expanded,
#cmc .sidebar.expanded {
    width: 200px;
}

/* .sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #ffffff;
} */

.sidebar ul li a {
    padding: 15px;
    font-size: 1em;
    display: flex; /* Use flex to align icon and text */
    align-items: center; /* Center items vertically */
    color: #ffffff;
    text-decoration: none;
}

.sidebar ul li a:hover {
    color: #fff;
    background: var(--menu-icon-hover);
}

.sidebar ul li.active {
    background-color: var(--menu-icon-hover);
}

#cmc .sidebar ul li a:hover {
    color: #fff;
    background: var(--menu-icon-hover-cmc);
}

.sidebar ul li a > * {
    margin-right: 8px; /* Adjust the margin as needed */
}

.toggle-container {
    display: flex;
    justify-content: left;
    align-items: left;
    margin-bottom: 15px; /* Adjust as needed */
}

.sidebar-toggle {
    width: 30px;
    height: 30px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #fff;
    margin-left: 8px;
}

/* @media (max-width: 768px) {
    .sidebar {
        width: 250px;
        margin-left: -250px;
    }

    .sidebar.expanded {
        margin-left: 0;
    }
} */
/* sidebar.css */

/* Existing styles for larger screens */

/* Adjust styles for screens up to 768px */
/* Sidebar.css */

/* Existing styles for larger screens */

/* Adjust styles for screens up to 768px */
@media (max-width: 768px) {
    .sidebar,
    #cmc .sidebar {
        width: 0; /* Hide the sidebar */
        overflow: hidden;
        transition: 0.2s;
    }

    .sidebar.expanded {
        width: 0;
        overflow: hidden; /* Show the sidebar when expanded */
    }

}

@media (min-width: 768px) {
    .kt_header_mobile_toggle {
      display: none;
    }
  }
  
  @media (min-width: 768px) {
    .burger-icon {
      display: none;
    }
  }
:root {
  --deletecolor: #DEEBFF;
  --viewcolor: #DEEBFF;
  --iconcolor: #fff;
  --bordercolor: #edeff7;
}

/* Hirearchy cards */

.hirearchy-card .card-custom {
  border: 1.5px solid #edeff7;
  border-radius: 10px;
  margin: 3px;
}

.hirearchy-card .add-card {
  background-color: var(--bordercolor);
  height: 65px;
  border-radius: 10px;
}

#cmc .hirearchy-card .add-card {
  background-color: var(--bgcolor);
  height: 65px;
  border-radius: 10px;
}

.card-section {
  height: 100vh;
  overflow-y: auto;
}

.tab-section {
  height: 100vh;
  border: 1.5px solid var(--bordercolor);
  border-radius: 10px;
}

.card-custom .hcard-header {
  justify-content: center;
  padding: 12px;
  background-color: var(--cardprimarycolor);
  border: 1.5px solid var(--cardprimarycolor);
  border-radius: 10px;
  /* border-top-left-radius: 0.42rem; */
  /* height: 80px; */
}

#cmc .card-custom .hcard-header {
  justify-content: center;
  padding: 12px;
  background-color: var(--cardprimarycolor-cmc);
  border: 1.5px solid var(--cardprimarycolor-cmc);
  border-radius: 10px;
}

/* .card-custom .selected-card {
  background-image: linear-gradient(to right,#35310a , #726B17 , #a39d56 );
  box-shadow:0px 0px 1px 1px rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  border: 1px solid #ebe711; 
} */

.card-custom .selected-card {
  background-color: #0b0355;
  border: 2px solid #ebe711;
  box-shadow: none;
  transform: scale(1.05); /* Slightly enlarges the card on selection */
  transition: transform 0.3s ease, background-color 0.3s ease, border 0.3s ease;
}
 
#cmc .card-custom .selected-card {
  background-color: var(--selected-card-color-cmc);
}

.card-custom .hcard-header.hoverable:hover {
  /* transform: scale(1.02); */
  background-color: #060B79;
  cursor: pointer;
}

#cmc .card-custom .hcard-header.hoverable:hover {
  /* transform: scale(1.02); */
  background-color: var(--hover-card-color-cmc);
  cursor: pointer;
}

.card-custom .hcard-title {
  display: flex;
  color: #fff;
  font-size: 1rem;
}

.card-custom .hcard-subtitle {
  color: #fff;
  font-size: 10px;
  font-weight: 200;
}

.expand-icons {
  color: #fff;
}

/* .form-container {
  height: 160px;
  overflow-y: auto;
} */

.form-list {
  font-size: 12px;
  background-color: transparent;
  border: none;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: start;
  text-align: start;
}

.form-list:hover {
  color: var(--cardprimarycolor);
}

.icon-box {
  background-color: var(--cardprimarycolor);
  padding: 5px;
  border-radius: 5px;
  border: none;
}

.view-button {
  background-color: var(--viewcolor);
}

.action-icons-edit {
  padding: 3px;
  border-radius: 3px;
  background-color: rgba(222, 235, 255, 0.35);
  border: none;
  margin-bottom: 2px;
}

.action-icons-delete {
  padding: 3px;
  border-radius: 3px;
  background-color: rgba(222, 235, 255, 0.35);
  border: none;
  margin-bottom: 2px;
}

.action-icons-view {
  padding: 4px;
  border-radius: 2px;
  margin-right: 8px;
  background-color: var(--viewcolor);
  border: none;
}

.form-view {
  color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-out;
  animation-fill-mode: both;
  visibility: visible;
}

.fade-in:nth-child(1) {
  animation-delay: 0.2s;
}

.fade-in:nth-child(2) {
  animation-delay: 0.4s;
}

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--cardprimarycolor) !important;
} */

/* .css-1aquho2-MuiTabs-indicator {
  background-color: var(--cardprimarycolor) !important;
} */

.menu-icon {
  width: 40px;
  height: 40px;
  background-color: var(--cardprimarycolor);
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
  border: none;
}

#cmc .menu-icon {
  width: 40px;
  height: 40px;
  background-color: var(--cardprimarycolor-cmc);
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
  border: none;
}

@media (max-width: 820px) {
  .card-section-hidden {
    display: none;
  }

  .card-section.visible {
    display: block;
  }
}

@media (min-width:899px) {
  .menu-icon {
    display: none;
  }
}

@media (max-width: 900px) {
  .card-section {
    display: none;
  }
}

/* Top Navigation */

.topnav-container {
  background-color: var(--cardprimarycolor);
  margin-top: 60px;
  margin-bottom: 50px;
}

.menu-list {
  display: flex;
  list-style: none;
  padding: 5px;
  margin: 1rem 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.menu-list .menu-item {
  padding: 0 20px;
}

.menu-list .menu-item .menu-text {
  color: #edeff7;
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .topnav-container {
    margin-top: 55px;
  }

  .menu-list .menu-item {
    padding: 0 10px;
  }
}

/* Medium devices (tablets, 601px to 900px) */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .topnav-container {
    margin-top: 55px;
  }
}

/* Large devices (desktops, 901px and up) */
@media only screen and (min-width: 901px) {
  .topnav-container {
    margin-top: 65px;
  }
}

/* Breadcrumbs.js */
/* .css-1jbmdz7-MuiButtonBase-root-MuiChip-root {
  background-color: #fff !important;
} */

/* Search.js */


.css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--cardprimarycolor) !important;
}

.selected-tab-color {
  color: var(--cardprimarycolor) !important;
}

#cmc .selected-tab-color {
  color: var(--cardprimarycolor-cmc) !important;
}

.hirearchy-card {
  margin-bottom: 10px;
  min-width: 216px;
}
.thead {
  font-weight: bold !important;
  font-size: 0.875rem !important;
}

.tData {
  font-size: 0.875rem !important;
}

.note {
  color: red;
  display: flex;
  flex-direction: row-reverse;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Adjust the height as needed */
}

.loading-container-subscription {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  /* Adjust the height as needed */
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.insert-btn {
  margin: 0 1px;
}

/* For all tables */

.css-1ygcj2i-MuiTableCell-root {
  font-family: "Poppins", sans-serif !important;
}

.css-1ex1afd-MuiTableCell-root {
  font-family: "Poppins", sans-serif !important;
}

/* Update Student Information modal */
.css-1w7ywiy-MuiSpeedDial-root.MuiSpeedDial-directionDown,
.css-1w7ywiy-MuiSpeedDial-root.MuiSpeedDial-directionRight {
  top: 16px;
  left: 46% !important;
}


/* Student.js */
.public-view-question-btn,
.public-edit-question-btn,
.view-public-assement-btn,
.edit-public-assement-btn,
.setup-public-assement-btn,
.grade-public-assement-btn,
.share-question-btn,
.edit-question-btn,
.view-question-btn,
.view-assement-btn,
.edit-assement-btn,
.flaticon-delete {
  background-color: #87878712 !important;
  color: #A2A5B5 !important;
  border-color: #F6F8Fa !important;
}

.public-view-question-btn:hover,
.public-edit-question-btn:hover,
.view-public-assement-btn:hover,
.edit-public-assement-btn:hover,
.setup-public-assement-btn:hover,
.grade-public-assement-btn:hover,
.share-question-btn:hover,
.edit-question-btn:hover,
.view-question-btn:hover,
.view-assement-btn:hover,
.edit-assement-btn:hover,
.send-assement-btn:hover {
  background-color: #EDEEF7 !important;
  color: var(--maincolor) !important;
  border-color: #EDEEF7 !important;
}

#cmc .public-view-question-btn:hover,
#cmc .public-edit-question-btn:hover,
#cmc .view-public-assement-btn:hover,
#cmc .edit-public-assement-btn:hover,
#cmc .setup-public-assement-btn:hover,
#cmc .grade-public-assement-btn:hover,
#cmc .share-question-btn:hover,
#cmc .edit-question-btn:hover,
#cmc .view-question-btn:hover,
#cmc .view-assement-btn:hover,
#cmc .edit-assement-btn:hover,
.send-assement-btn:hover {
  background-color: #E5F5FF !important;
  color: var(--maincolor-cmc) !important;
  border-color: #E5F5FF !important;
}

.flaticon-delete:hover {
  background-color: #FFF4F5 !important;
  color: #FF6071 !important;
  border-color: #FFF4F5 !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

/* .css-10d9dml-MuiTabs-indicator {
  background-color: #5458AF !important;
} */

.form-select {
  height: calc(1.5em + 1.3rem + 2px) !important;
  padding: 0px 0px 0px 10px !important;
}

.react-datePicker {
  width: 100%;
}

.disabled-select {
  background-color: #f3f6f9;
  border: 1px solid #bababa;
}

.hide-card-header {
  display: none;
}

.payment-amount {
  text-align: end !important;
}

.payment-total {
  padding: 20px 10px;
  border: 1px solid #F3F4F6;
  border-left: 3px solid #7679bf;
  border-radius: 5px;
  background-color: #FAFBFC;
  font-weight: 600;
  margin-top: 10px;
}

.discount-total {
  padding: 20px 10px;
  border: 1px solid #fafcfb;
  border-left: 3px solid #1bc598;
  border-radius: 5px;
  background-color: #fafcfb;
  font-weight: 600;
  margin-top: 10px;
}

.outstanding-total {
  padding: 20px 10px;
  border: 1px solid #fffff8;
  border-left: 3px solid #FFA800;
  border-radius: 5px;
  background-color: #fffff8;
  font-weight: 600;
  margin-top: 10px;
}

.main-payment-total {
  padding: 10px 10px;
  border: 1px solid #F3F4F6;
  border-left: 3px solid #7679bf;
  border-radius: 5px;
  background-color: #FAFBFC;
  font-weight: 600;
}

#exampleFormControlTextarea1::placeholder {
  font-family: 'Poppins', sans-serif;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
@media (max-width:1200px) {
  .subPackTitles {
    font-size: 12px;
  }
}

@media (max-width:1080px) and (min-width:768px) {
  .subPackTitles {
    height: 32px;
    overflow: auto;
  }
}
h1,
h2,
h3,
h4,
h5,
p,
label,
th,
td,
input,
select,
MenuItem {
  font-family: "Poppins", sans-serif !important;
}


/* siteannouncement.css */

.custom-header {
  background-color: var(--maincolor);
  color: #fff;
}

#cmc .custom-header {
  background-color: var(--maincolor-cmc);
  color: #fff;
}

/* progressbar.css */
.custom-progress-bar .progress-bar {
  background-color: var(--maincolor);
}

#cmc .custom-progress-bar .progress-bar {
  background-color: var(--maincolor-cmc);
}



/* tiles.css */
.DashboardTiles {
  margin: 20px;
}

.tilePaper-dashboard {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tileTitle-dashboard {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.tileContent-dashboard {
  display: flex;
  align-items: center;
}

.tileIcon-dashboard {
  margin-right: 10px;
}

.tileCount-dashboard {
  font-size: 1.5rem;
  font-weight: bold;
}




/* 
Best-selling-courses.css */

.best-selling-courses {
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.best-selling-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.best-selling-th {
  background-color: var(--maincolor);
  color: #fff;
}

#cmc .best-selling-th {
  background-color: var(--maincolor-cmc);
  color: #fff;
}

.best-selling-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.best-selling-table th {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.best-selling-table tr:hover {
  background-color: #D9DAF1;
}

/* .chart-container {
overflow-x: auto;
width: 100%;
margin-bottom: 20px; 
} */



/* managedashboard.css */
.scrollable-chart {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
}

.progress-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
}

.doughnutchart-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  /* height: 400px; */
  /* width: auto; */
  padding-top: 20px;
}

.coursetable-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: 380px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

}

.attendance-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

}

.drilldown-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

}

.doughnutchart1-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: 380px;
  padding-top: 20px;

}


/* drillchart.css */


.content-drillchart th,
.content-drillchart td {
  border: 1px solid #D9DAF1;
  padding: 8px;
  text-align: left;
}

.content-drillchart th {
  background-color: #fffdfd;
}

.content-drillchart tr:hover {
  background-color: #D9DAF1;
}

.content-drillchart.level-1 tr {
  background-color: #e9e9f5;
}

.content-drillchart.level-2 tr {
  background-color: #e9e9f5;
}

.content-drillchart.level-3 tr {
  background-color: #e9e9f5;
}

.container-drillchart {
  border: 1px solid #ccc;
  height: 300px;
  max-width: 600px;
  padding: 10px;
  margin: 20px auto;
  background-color: #D9DBF1;
  border-radius: 5px;
  overflow-y: auto;
}

.content-drillchart {
  margin-top: 6px;
  margin-bottom: 10px;
  border-collapse: collapse;
  width: 100%;
}


.top-right-buttons-drillchart {
  position: absolute;
  top: 0;
  right: 10px;
  margin: 10px;
}

.top-right-buttons-drillchart button {
  color: #ffffff;
  background-color: #545cb4;
  border: none;
  border-radius: 5px;
  padding: 10px 12px;
  margin-right: 5px;
  cursor: pointer;
}

#cmc .top-right-buttons-drillchart button {
  color: #ffffff;
  background-color: var(--maincolor-cmc);
  border: none;
  border-radius: 5px;
  padding: 10px 12px;
  margin-right: 5px;
  cursor: pointer;
}



/* 
.row .content-drillchart:hover {
background-color: #f0f0f0;
}
.content-drillchart.level-1 .row {
background-color: #f0f0f0; 
}

.content-drillchart.level-2 .row {
background-color: #e0e0e0;
}

.content-drillchart.level-3 .row {
background-color: #d0d0d0; 
}

.content-drillchart .row:hover {
background-color: #f5f5f5; 
}  */




/* 
attendance.css */

.attendence-chart {
  margin-top: 50px;
}

/* notice-board.css */
.notice-board {
  margin: 10px;
}

#notice-board-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
}

#notice-card-header {
  background-color: var(--maincolor);
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

#cmc #notice-card-header {
  background-color: var(--maincolor-cmc);
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

#notice-header-title {
  margin: 0;
  color: white;
}

#notice-card-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.sender-info {
  flex-grow: 1;
}

.sender-name {
  font-weight: bold;
  margin: 0;
}

.sender-role {
  margin: 0;
}

.notice {
  margin: 0;
}

/* Parent Dashboard */
.parent-section-title {
  font-family: 'Poppins', sans-serif !important;
  color: var(--maincolor);
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 24px;
  letter-spacing: -0.5px;
}

.sec-topic{
  color: var(--maincolor);
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}

#cmc .parent-section-title {
  font-family: 'Poppins', sans-serif !important;
  color: var(--maincolor-cmc);
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 24px;
  letter-spacing: -0.5px;
}

.announcement-container {
  border: 1px solid #EDEEF7;
  border-radius: 10px;
  background-color: white;
}

.announcement-card {
  border: 2px solid #EDEEF7;
  padding: 20px 5px;
  border-radius: 10px;
  cursor: pointer;
}

#cmc .announcement-container {
  border: 1px solid #E8F6FF;
  border-radius: 10px;
}

#cmc .announcement-card {
  border: 2px solid #E8F6FF;
  padding: 20px 5px;
  border-radius: 10px;
}

.announcement-title {
  font-size: 16px;
}

.announcement-content {
  font-size: 10px;
}

.child-view-icon {
  padding: 4px;
  border-radius: 5px;
  margin-right: 8px;
  background-color: var(--maincolor);
  border: none;
}

#cmc .child-view-icon {
  padding: 4px;
  border-radius: 5px;
  margin-right: 8px;
  background-color: var(--maincolor-cmc);
  border: none;
}

.child-dropdown {
  color: #5F6276;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

/* Admin Dashboard */
.view-more {
  color: #545cb4;
  font-size: 10px;
  font-weight: 600;
  padding-bottom: 15px;
  cursor: pointer;
}

#cmc .view-more {
  color: var(--maincolor-cmc);
  font-size: 10px;
  font-weight: 600;
  padding-bottom: 15px;
  cursor: pointer;
}

.users-container {
  background-color: #EDEEF7;
  border-radius: 10px;
  padding: 10px 0;
  /* height: 160px; */
}

#cmc .users-container {
  background-color: #F2FAFF;
  border-radius: 10px;
  padding: 20px 0;
  /* height: 160px; */
}

.user-cards {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 0;
  border: 3px solid #EDEEF7;
}

.pprcountTopics{
  margin-left: 8px;
}

#cmc .user-cards {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 0;
  border: 3px solid #E8F6FF;
}

.user-card-value {
  font-size: 24px;
  font-weight: 600;
  padding-top: 5px;
  color: #231F20;
}

.dashboard-top-container {
  background-color: #edeef7;
  border-radius: 10px;
  padding: 10px 0;
}

#cmc .dashboard-top-container {
  background-color: #F2FAFF;
  border-radius: 10px;
  padding: 10px 0;
}

.dashboard-bottom-container {
  border: 1px solid #edeef7;
  border-radius: 10px;
}

#cmc .dashboard-bottom-container {
  border: 1px solid #E8F6FF;
  border-radius: 10px;
}

.section-title {
  font-family: "Poppins", sans-serif !important;
  color: var(--maincolor);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

#cmc .section-title {
  font-family: "Poppins", sans-serif !important;
  color: var(--maincolor-cmc);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.dashboard-cards {
  background-color: #fff;
  border-radius: 10px;
  border: 5px solid #edeef7;
}

.teacher-ann-dashboard-cards {
  background-color: #edeef7;
  border-radius: 10px;
  padding: 8px;
  height: 60vh;
  overflow-y: auto;
}

.Announcements {
  background-color: #fff;
  border-radius: 10px;
}

#cmc .dashboard-cards {
  background-color: #fff;
  border-radius: 10px;
  border: 5px solid #E8F6FF;
}

.card-value {
  font-size: 24px;
  font-weight: 600;
  padding-top: 5px;
  color: #231f20;
}

/* .child-view-icon {
  padding: 4px;
  border-radius: 5px;
  margin-right: 8px;
  background-color: var(--maincolor);
  border: none;
} */

.view-more {
  color: #545cb4;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
}

#cmc .view-more {
  color: var(--maincolor-cmc);
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
}

/* svg,
a {
  color: #545cb4;
} */

.announcement-title {
  font-size: 16px;
}

.announcement-content {
  font-size: 10px;
}

#cmc .announcement-card {
  border: 2px solid #E8F6FF;
  padding: 20px 5px;
  border-radius: 10px;
}

/* CMC and EdSider quick guide */

.quickguide-container {
  border: 1px solid #c8c9f9;
  border-left: 8px solid #7779CF;
  border-radius: 5px;
}

#cmc .quickguide-container {
  border: 1px solid #a3e6ff;
  border-left: 8px solid #a3e6ff;
  border-radius: 5px;
}

.quickguide-heading {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  padding-left: 15px;
  font-weight: 600;
}

.parent-heading {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

#cmc .quickguide-heading {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  padding-left: 15px;
  font-weight: 600;
}

.quickguide-content {
  border: 1px solid #c8c9f9;
  background-color: #f5f5ff;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px;
  width: 100%;
  padding: 5px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#cmc .quickguide-content {
  border: 1px solid #b3edff;
  background-color: #eafaff;
  border-radius: 5px;
  cursor: pointer;
}

.quickguide-text {
  color: var(--maincolor);
  font-weight: 200;
}

#cmc .quickguide-text {
  color: var(--maincolor-cmc);
  font-weight: 500;
}

.steps-label {
  padding: 5px 10px;
  background-color: #f5f5ff;
  color: var(--maincolor);
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #c8c9f9;
}

#cmc .steps-label {
  padding: 5px 10px;
  background-color: #eafaff;
  color: var(--maincolor-cmc);
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #b3edff;
}

.steps-btn {
  background-color: var(--maincolor);
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
}

#cmc .steps-btn {
  background-color: var(--maincolor-cmc);
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
}

.steps-content-text {
  font-size: 12px;
  padding: 10px 0px;
}

@media screen and (max-width: 1057px) {

  .congratulations-img-left,
  .congratulations-img-right {
    display: none;
  }
}

#cmc .steps-content-text {
  font-size: 12px;
  padding: 10px 0px;
}


.enrolled {
  background: linear-gradient(90deg, #7F84DB 0%, #84BFE1 100%);
  border-radius: 10px;
  border: 2px #EDEEF7 solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  padding: 1rem;
}

.completed {
  background: linear-gradient(90deg, #238BCE 0%, #00C6FF 100%);
  border-radius: 10px;
  border: 2px #EDEEF7 solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  padding: 1rem;
}

.whiteTxt {
  color: white !important;
  margin-top: 0px;
}

.colPadding {
  padding: 0px;
}

.pprEnrollmnt {
  cursor: pointer;
  box-shadow: 1px 1px 8px 0 rgba(54, 54, 54, 0.2), 1px 1px 8px 0 rgba(65, 65, 65, 0.19);
  border-radius: 5px;
  padding: 10px;
}

.parentData {
  color: #939393;
  font-weight: 500;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (max-width:767px) {
  .firstCol {
    padding: 0px;
  }

  .stdDash {
    padding: 0px !important;
  }

  .displayWeb {
    display: none;
  }

  .welcome-topic {
    display: flex;
    justify-content: center;
  }

  .welcomeParagraph2 {
    display: none;
  }

  .parentD {
    margin-top: 5px !important;
  }
}

.parentData2 {
  color: #000000;
  font-weight: 500;
}

.confirmstatus {
  background-color: #939393;
  border-radius: 5px;
  padding: 4px;
}

.ArrowForwardIcon {
  color: #8089DB;
}

.article {
  color: #7779CF;
  background-color: #F0F0FF;
  padding: 5px;
}

.articleIcon {
  color: #414141;
  margin-right: 5px;
}

.enrollPprsSpinner {
  color: white;
}

.availableSub {
  color: #4F4F4F;
  margin-top: 0px;
  font-weight: 600;
  font-size: 16px;
}

.enrollTopics {
  color: #4F4F4F;
  margin-top: 0px;
  font-weight: 500;
  font-size: 20px;
}

.medium {
  color: #818181;
  font-weight: 500;
  font-size: 14px;
}

.ptag1 {
  color: #818181;
  font-size: 12x;
}

.subjectsAvailable {
  color: #818181;
  font-weight: 400;
  font-size: 12px;
  padding: 10px 0px;
}

.ppr_details {
  background-color: #F0F0FF;
  padding: 15px;
  border-radius: 10px;
  overflow: auto;
  margin: 10px 0px;
}

.dashContent {
  margin: 20px;
}

.handIcon {
  width: 25px;
}

.welcomeMiniImg {
  height: 200px;
  margin: 30px 30px 20px 30px;
}

.welcomeParagraph {
  color: #6D6D6C;
  font-weight: 500;
  margin-bottom: 10px !important;
  margin: 5px;
  height: 6vh;
  text-align: center;
}

.alignItems {
  display: flex;
  align-items: center;
}

.enrollTopic {
  margin: 10px 0px;
}

.pprEnrollments {
  border: 2px #D9D9D9 solid;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5vh;
  margin: 5px;
  overflow: auto;
}

.subPackges {
  border: 2px #D9D9D9 solid;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5vh;
  margin: 5px;
}
@media (min-width: 916px) and (max-width: 993px) {
  .ppr_details {
    height: 54vh !important;
  }
}

@media (min-width: 767px) and (max-width: 915px) {
  .ppr_details {
    height: 48vh !important;
  }
}

@media (min-width: 767px) {
  .displayMobile {
    display: none;
  }

  .subPackges {
    height: 33vh;
    min-height: 260px;
  }

  .ppr_details {
    height: 61vh;
  }

  .pprEnrollments {
    /* min-height: 330px; */
    height: 40vh;
  }

  .parentD {
    height: 30vh;
  }

  .quickGuide {
    height: 16vh;
  }
}

@media (min-height: 500px) and (max-height: 700px) {
  .quickHeight {
    height: 20vh;
  }

  .subPackges {
    height: 34vh;
    min-height: 260px;
  }
}

.quickGuide {
  border: 2px #D9D9D9 solid;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 10px;
}

.parentD {
  border: 2px #D9D9D9 solid;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  overflow: auto;
}

.gridSpace {
  margin: 10px;
}

.dataCards {
  margin-top: 4vh;
}

.w3-container {
  width: 400px;
  border-radius: 15px;
  border: 4px solid #A7C2CB;
  border-image-slice: 1;
  padding: 15px;
  background-color: #2D1769;
  margin: 10px;
}

.name {
  font-size: 20px;
  font-weight: 500;
}

.assignmentName {
  font-size: 12px;
  font-weight: 300;
}

.assignmentCompleted {
  background-color: #C6EEE4;
  color: #55A791;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  padding: 2px 5px;
}

.userCode {
  font-size: 12px;
  font-weight: 300;
}

.userCode2 {
  font-size: 12px;
  font-weight: 500;
}

.subData {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

.flex_0 {
  flex: 0% 1;
}

.theData {
  font-size: 20px;
  font-weight: 700;
}

.grbtn {
  display: flex;
  justify-content: space-around;
}

.gradebtn {
  background-color: #E4E6FF;
  color: #3A8BD3;
  border-radius: 10px;
  padding: 5px 10px;
}

.lineGrade {
  margin-bottom: 15px;
}

.lineItems {
  margin-bottom: 10px !important;
}

.subjectTopic {
  display: flex;
  justify-content: end;
}

.subscription_card {
  border-radius: 10px;
  width: 100%;
  margin: 10px;
}

.subFirstLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.seeAll {
  color: #3A8BD3;
}

.gold {
  box-shadow: 0 0px 4px 0 #FFD145;
}

.gold:hover {
  box-shadow: 0 0px 12px 0 #FFD145;
}

.silver {
  box-shadow: 0 0px 6px 0 #949393;
}

.silver:hover {
  box-shadow: 0 0px 12px 0 #949393;
}

.bronze {
  box-shadow: 0 0px 4px 0 #cd7f32;
}

.bronze:hover {
  box-shadow: 0 0px 12px 0 #cd7f32;
}

.subPackageReserve {
  padding: 5px 20px 10px 20px;
}

.stdDash {
  background-color: #EBF9FF;
  border-radius: 10px;
  padding: 10px;
}

.whiteBgClr {
  background-color: white;
}

.watermark {
  font-family: 'Rancho', cursive !important;
  color: #5BB1E3;
  opacity: 0.3 !important;
  font-size: 4rem;
  text-align: center;
  font-weight: 400;
  line-height: 4rem;
  margin-top: 16%;
}

.stdpopupLeftside {
  overflow: auto;
  max-height: 450px;
}

@media (min-width:767px) {
  .stdpopupLeftside {
    background-color: #EBF9FF;
    padding: 5px;
  }

  .popupAccordions {
    background-color: #EBF9FF !important;
  }

  .studentPopupCard {
    background-color: #EBF9FF;
  }
}

.popupAcc {
  margin: 5px;
}

.studentPopup {
  padding-bottom: 0px !important;
}

.smtext {
  font-size: 12px;
  color: #000000;
  font-weight: 400;
}

.pprcounts1 {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-top: 10px;
}

.adminDashData1{
  max-height: 76vh;
  overflow: auto;
}

.teacherDashData1{
  height: 35vh;
  overflow: auto;
}

.teacherDashData2{
  height: 35vh;
  overflow: auto;
}

.pprcounts2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-top: 10px !important;
}

@media (min-width:768px) {
  .pprcounts1 {
    margin-right: 5px;
  }

  .pprcounts2 {
    margin: 5px;
  }
}

.TeacherImg {
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-top: 10px;
}

@media (max-width:768px) {
  .qguide {
    margin-top: 8px;
  }

  .TeacherImg {
    display: none;
  }

  .TeacherImg2 {
    display: none;
  }
}


.teacher-dashboard-cards {
  background-color: #EDEEF7;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  min-height: 14vh;
  display: flex;
  justify-content: center;
}

.teacher-card-value {
  font-size: 24px;
  font-weight: 600;
  padding-top: 5px;
  color: #231f20;
  display: flex;
  justify-content: center;
}

.teacher-card-topic {
  padding: 4px;
  text-align: center;
}

.lateAsseCard {
  border-radius: 10px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  padding: 4px;
  margin: 5px 0px;
}

.asseCardDetail {
  margin: 5px;
}

.lateAsseContent {
  border: 2px solid #D9D9D9;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  overflow: auto;
}

.height60vh{
  height: 60vh;
}

.stateAsses {
  padding: 8px;
  border-radius: 16px;
  background-color: #CFF4E4;
  color: #1d583f;
  font-size: 10px;
  font-weight: 500;
}

.created_on {
  font-size: 10px;
}

.annTopic {
  font-weight: 500;
  margin-bottom: 8px;
}

.anncont {
  background-color: #EDEEF7;
  text-align: justify;
  padding: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
}
/* PaperMapping.js */
.sticky-card-body {
    background-color: #fff;
    z-index: 1;
}

@media only screen and (max-width: 991px) {
    .filterBySec {
        padding-left: 12.5px !important;
    }

    .searchBySec {
        margin-bottom: -2.4rem !important;
    }
}

.filterCard {
    margin: 8px;
    padding: 8px;
    border: 2px #e0e0e0 solid;
    border-radius: 12px;
    overflow-y: auto;
}

.ClearbuttonFilter {
    padding: 0px 8px !important;
    height: 32px;
    font-weight: 500 !important;
}

.ClearbuttonFilter:hover {
    background-color: #074aaf !important;
    color: white;
}

.filterpprs{
    background-color: #f3f3f3;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 8px;
    border-radius: 8px;
}
.thead {
  font-weight: bold !important;
  font-size: 0.875rem !important;
}

.tData {
  font-size: 0.875rem !important;
}

.stu-thead {
  font-weight: bold !important;
  font-size: 0.875rem !important;
}

.stu-tData {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: -0.04rem !important;
}

.stu-variant-class {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.2px;
}

/* Enrolled Papers */

.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  font-weight: 400;
  color: var(--table-theme-color) !important;
  font-size: 0.875rem;
  letter-spacing: -0.01rem;
  text-transform: unset;
}

.css-ahj2mt-MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

.select-student-dropdown {
  height: 43px !important;
}
.wizard-title {
  color: #000000 !important;
  font-size: 14px !important;
}

.wizard-desc {
  color: #000000 !important;
}

.navi-text {
  font-weight: 400 !important;
  font-size: 1.1rem !important;
  margin-bottom: 5px;
}

/* ExamGradeReport.js */
.access-grade-report {
  color: #8c8c96;
  font-size: 1.2rem;
  font-weight: 500;
  /* margin-left: 1rem; */
  margin-top: 0.5rem;
}

.highlighted {
  background-color: #f0f8ff;
  border: 2px solid #007bff;
  padding: 10px;
  transition: background-color 0.5s ease;
}

/* Questions.js */

.exam-header {
  background-color: #cde4ff;
  border-radius: 5px;
  margin-right: 12px;
  margin-left: 12px;
  padding-top: 20px;
}

.time-count-text {
  font-size: 15px !important;
}

.time-remaining-text {
  color: #252525 !important;
  font-weight: 600;
}

.paper-header {
  margin: -12px 12px -26px 12px;
  background-color: #cde4ff;
  padding-top: 20px;
  border-radius: 5px;
}

.btn-primary {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 13px;
}

.btn-view-questions,
.answer-all-questions {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #252525 !important;
}

.questions-section {
  border-radius: 5px;
  background-color: #cde4ff;
}

.summary-section {
  border-radius: 5px;
  background-color: #d9ffeb;
}

.btn-unanswered-question {
  background-color: #fff;
  margin: 6.5px !important;
}

.btn-icon {
  width: 28px !important;
  height: 28px !important;
}

.btn-unanswered-question:hover {
  background-color: #f2f2f2;
}

/* Modal.js */

/* .modal {
  background-color: rgba(0, 0, 0, 0.547);
} */

/* PaperQuestions.js */

.paper-btn {
  display: flex;
  align-items: center;
}

.fixed {
  position: fixed;
  bottom: 80px;
  left: 55px;
  z-index: 1;
}

.parent-class {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}

.single-column {
  grid-template-columns: repeat(1, 1fr) !important;
}

/* For the images on the question paper */
figure {
  margin: 1em 5px;
}

/* Screen larger than 600px? 2 column */
@media (max-width: 575.98px) {
  .fixed {
    bottom: 55px;
  }
}

@media (min-width: 600px) {
  .parent-class {
    grid-template-columns: repeat(2, 1fr);
  }

  .fixed {
    bottom: 75px;
  }
}

/* Screen larger than 900px? 4 columns */
@media (min-width: 900px) {
  .parent-class:not(.single-column) {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* PaperReview.js */
.checkbox>input.correct-color:checked~span {
  background-color: #00a86b;
}

.question-container {
  border: 2px solid #EDEEF7;
  margin: 20px 20px;
  padding: 20px 0px;
  border-radius: 5px;
}

.answer-sheet-text {
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
}

.model-guidance-text {
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

.answersheet-correct {
  border: 1px solid #2cd59c;
  background-color: #e5faf3;
  color: #2cd59c;
  border-radius: 3px;
}

.answersheet-wrong {
  border: 1px solid #FF6071;
  background-color: #FFEFF0;
  color: #FF6071;
  border-radius: 3px;
}

.submission-document {
  font-family: "Poppins", sans-serif;
}

.submission-document:hover {
  text-decoration: underline;
  color: var(--maincolor);
}

/* PaperGradeReport.js */

.exam-details {
  border-left: 3px solid var(--maincolor);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #FAFBFC;
  padding: 20px 0px;
}

.grade-report-box {
  background-color: #f6f8fa;
  padding: 10px 10px;
  border-radius: 0.425rem;
  border: 0.5px solid #edf1fe;
}

.grade-report-text {
  color: var(--textcolor);
}

/* Self Grade */
.rdw-image-center {
  display: flex;
  justify-content: start !important;
}

/* PaperMapping.js */
.sticky-card-body {
  background-color: #fff;
  z-index: 1;
}

.sub-view-editor .editorClassName {
  overflow: hidden !important;
}

.rdw-editor-main {
  overflow: hidden !important;
}

.boxIndicators {
  position: -webkit-sticky;
  position: sticky;
  top: 76px;
  right: 16px;
}

.form-check {
  padding: 0px;
}

.AllQcheckBox {
  padding-left: 16px;
}

.view {
  margin: 0px;
}

.singlePgview {
  margin: 10px 0px !important;
}

.timeLeft {
  padding: 5px 0px;
}

.dashboardEnrolledPath {
  margin-top: 10vh;
  right: 0px;
  width: 90%;
}

.paperDatas {
  width: 90%;
}

.Qsummary {
  width: 90%;
}
.switch input:empty ~ span:before, .switch input:empty ~ span:after {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    content: ' ';
    transition: all 100ms ease-in;
    background-color: rgb(204, 204, 204);
}

.switch input:empty ~ span:after {
    opacity: 1;
    color: #ffffff;
    background-color: #ffffff;
}

.switch input:checked ~ span:after {
    opacity: 1;
    color: #ffffff;
    background-color: #3699FF;
}


.fraction {
    display: inline-block;
    vertical-align: middle;
}

.numerator {
    display: block;
}

.denominator {
    display: block;
    border-top: 1px solid black;
}
.DragDrop {
    font-family: Arial, sans-serif;
}

.customization-form {
    margin-bottom: 20px;
}

.customization-form label {
    display: block;
    margin-bottom: 10px;
}

#dragContainer span {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #ddd;
    border: 1px solid #ccc;
    cursor: pointer;
}

.drop-box {
    display: inline-block;
    width: 100px;
    height: 20px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.rbc-header {}

.rbc-event {
    width: auto;
    /* Adjust the width of the box as needed */
    height: 10px;
    /* Adjust the height of the box as needed */
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 1px;
    color: white;
    font-size: 9px;
    cursor: pointer;
}

.rbc-background-event {
    padding: 0px !important;
    border-radius: 0px !important;
    border: none !important;
    background-color: transparent !important;
}

.rbc-time-slot {
    background-color: #eaf3fa;
    color: #74a4c3;
    border-color: aqua !important;
}

.rbc-event-label {
    display: none !important;
}

.rbc-events-container {
    width: 100% !important;
}

.rbc-timeslot-group {}

.event-box {
    width: 50px;
    /* Adjust the width of the box as needed */
    height: 30px;
    /* Adjust the height of the box as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    cursor: pointer;
}

.custom-event-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-event-wrapper>* {
    margin-right: 5px;
    /* Adjust margin between events as needed */
}

.rbc-toolbar .rbc-toolbar-label {
    font-size: 13px;
}

.rbc-show-more {
    font-size: 8.5px;
}
.no-arrow input[type=number]::-webkit-inner-spin-button,
.no-arrow input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.packageCard {
  border-radius: 16px;
  min-height: 400px;
  box-shadow: 0 0px 2px 1px rgba(219, 4, 129, 0.3);
}

@media (max-width: 1150px) and (min-width: 1000px) {
  .packageCard {
    min-height: 450px !important;
  }

  .disabledPackageCard {
    min-height: 450px !important;
  }
}

.packageCard:hover {
  box-shadow: 0 1px 3px 5px rgba(235, 152, 29, 0.2);
  transition: box-shadow 0.3s;
}

.disabledPackageCard {
  border-radius: 16px;
  min-height: 400px;
  box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.3);
}

.packName {
  font-size: 14px;
  font-weight: 600;
}

.packDis {
  font-size: 12px;
  font-weight: 400;
}

.packTopic {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: #E0ECFF;
  color: #145ACB;
}

.disabledPackTopic {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: #b9b9b9;
  color: #ffffff;
}

.packValue {
  font-size: 20px;
  font-weight: 400;
}

.promoInput {
  background-color: #f5f5f5;
  color: #ABABAB;
}

.applyBtn {
  background-color: #FC6C6C !important;
  font-size: 12px;
}

.packHr1 {
  width: 20%;
  height: 2px;
  color: #31A0FE;
  display: flex;
  justify-content: start;
  margin: 12px !important;
}

.packCredit {
  font-size: 24px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin-bottom: 16px;
}

.packPromo {
  font-size: 10px !important;
  background-color: #FC6C6C;
  padding: 4px 10px;
  margin: 8px 0px;
  border-radius: 4px;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.packPrice {
  font-size: 14px;
  padding: 4px;
}

.packHr2 {
  color: #D9DEEF;

}

.packbtn {
  background-color: #FC676C !important;
  color: white !important;
  font-size: 18px;
  font-weight: 600 !important;
  margin-top: 16px !important;
}

.packbtnDisabled {
  background-color: #b9b9b9 !important;
  color: white !important;
  font-size: 18px;
  font-weight: 600 !important;
  margin-top: 16px !important;
}

.packDetails1 {
  font-size: 12px;
  padding: 4px;
}

.packDetails2 {
  font-size: 10px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin-top: 4px;
}

/* styles.css */
.background-animation {
  position: fixed;
  /* Make it cover the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(203, 203, 255, 0.4);
  /* Your desired background color */
  z-index: 9999;
  /* Ensure it's on top of other content */
  display: flex;
  justify-content: center;
  /* Center the Lottie animation */
  align-items: center;
  /* Center the Lottie animation */
}
.messages {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
}
.status_bar{
    height: 30px;
    margin-top: '-20px';
    max-width: "100%";
    background-color: #fafafa;
    border: none;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior:smooth;
}
.status_bar::-webkit-scrollbar{
    display: none;
}
.messages {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
}
.status_bar{
    height: 30px;
    margin-top: '-20px';
    max-width: "100%";
    background-color: #fafafa;
    border: none;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior:smooth;
}
.status_bar::-webkit-scrollbar{
    display: none;
}

.chat-bg {
    background-image: url(/static/media/chatbg.7fd7ab16.png);
}

.user-remove-btn {
    background-color: #FDEDEE !important;
    color: #F12E2D !important;
    text-transform: unset !important;
    letter-spacing: -0.2px !important;
    font-weight: bold !important;
}

.add-user-btn {
    background-color: #F0F0FF !important;
    color: #7779CF !important;
    text-transform: unset !important;
    letter-spacing: -0.2px !important;
    font-weight: bold !important;
}

.add-remove-members {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 11px;
}

.chat-members-container {
    border: 2px solid #EDEFF7;
    border-radius: 5px;
}
/*for larger screens */
.landing-btn {
    font-size: 15px; 
  }
  .btn-admin-mobile {
    display: none;
  }
  .btn-admin-mobile-more{
    display: none; 
}
.btn-student-mobile-more{
    display:  none; 
}
  
  /*for smaller screens*/
  @media (max-width: 660px) {
    .landing-btn {
      font-size: 11px; 
    }
  
    .btn-admin-desktop {
      display: none; 
    }
  
    .btn-admin-mobile {
      display: inline-block; 
    }
    .btn-admin-mobile-more{
        display: none; 
    }
    .btn-student-mobile-more{
        display: none; 
    }
    
  }
  @media (max-width: 380px) {
    .landing-btn {
      font-size:10px; 
    }
  
    .btn-admin-desktop {
      display: none; 
    }
  
    .btn-admin-mobile {
      display: none; 
    }
    .btn-admin-mobile-more{
        display:  inline-block; 
    }
    .btn-student-mobile-more{
        display:  inline-block; 
    }
    .btn-student-desktop{
        display: none; 
    }
  }
  
 
/* RESET */

img {
  display: block;
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

:root {
  /* FONTS */
  --FF: "DM Sans", sans-serif;
  --FF-MAINHEADING: "Boogaloo", cursive;
  --FF-SECTIONHEADING: "Poppins", sans-serif;

  --FS-MAINHEADING: 12rem;
  --FS-SECTIONTITLE: 5.75rem;
  /* --FS-SUBHEADING: 1.125rem;
  --FS-DETAILTEXT: 1.125rem;
  --FS-SMALLTEXT: 1.125rem;
  --FS-SMALLESTTEXT: 1.125rem; */

  /* COLORS */
  --BGCOLOR: #fff;
  --SECTION-BGCOLOR: #edeef7;
  --BUTTON-COLOR: linear-gradient(to right,
      var(--DARKBLUE),
      var(--PRIMARYBLUE));
  --FONT-COLOR: #2c3440;
  --PRIMARYBLUE: #3d9bff;
  --DARKBLUE: #187de4;
  --LIGHTBLUE: #edeef7;
  --NAV-BGCOLOR: #fff;
  --MAIN-CLR: #0B1360;
  --BLK-CLR: #000000;

  /* BORDERS */
  --BORDER: 3px solid var(--LIGHTBLUE);
  --BORDER-RADIUS: 10px;
  --BUTTON-BORDER-RADIUS: 50px;
}

/* COMMON STYLES */

html {
  scroll-behavior: smooth;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.bg-landing-page {
  background-color: #fff;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  /* padding-top: 10px; */
  /* padding-bottom: 60px; */
}

.colPadding {
  padding: 0px;
}

.section-container {
  /* padding: 5rem 0; */
}

.footer-container {
  background-color: #EDEEF7 !important;
  padding-top: 5rem;
  padding-bottom: 1rem;
}

.light-blue-container {
  background-color: var(--LIGHTBLUE);
}

.edsider-section-title {
  font-family: var(--FF-SECTIONHEADING);
  font-size: var(--FS-SECTIONTITLE);
  letter-spacing: -0.3rem;
  color: var(--BLK-CLR);
  font-weight: 600;
  padding-top: 50px;
  padding-bottom: 20px;
}

.white-section-title {
  color: #fff;
}

/* NAVIGATION SECTION */

.navigation-bg {
  background-color: #FFF;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.navig-links {
  color: var(--FONT-COLOR);
  margin-left: 1.5rem;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--FONT-COLOR);
}

.navigation-links {
  font-family: var(--FF-SECTIONHEADING);
  color: var(--FONT-COLOR);
  font-weight: 400;
  font-size: 1.2rem;
  font-style: normal;
  letter-spacing: -0.05rem;
}

.useful-links {
  white-space: nowrap;
  padding-right: 16rem;
}

.navbar-collapse {
  flex-grow: 0;
}

.signin-btn {
  --button-default-color: linear-gradient(90deg, #3F11A2 0%, #3D9BFF 100%);
  --button-hover-color: linear-gradient(90deg, #3d9bff 0%, #187de4 100%);

  background: var(--button-default-color);
  border-radius: 50rem;
  font-family: var(--FF-SECTIONHEADING);
  color: #fff;
  border: none;
  padding: 10px 20px;
  background-color: #fff;
  white-space: nowrap;
}

.register-btn,
.getstarted-btn {
  --button-default-color: linear-gradient(90deg, #3F11A2 0%, #3D9BFF 100%);
  --button-hover-color: linear-gradient(90deg, #3d9bff 0%, #187de4 100%);

  background: var(--button-default-color);
  border-radius: 50rem;
  padding: 18px 30px;
  border: none;
  font-family: var(--FF-SECTIONHEADING);
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  width: 20rem !important;
  transition: background-color 0.5s ease-in-out;
  margin-top: 64px;
  margin-bottom: 20px;
  padding: 20px 40px;
}

.btn2 {
  width: 15rem;
}

.register-btn:hover,
.getstarted-btn:hover {
  background: var(--button-hover-color);
}

.back-btn {
  background-color: #5458AF;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  color: #FFF !important;
  width: 75px;
  display: flex;
  justify-content: center;
  /* margin-left: 30px; */
}

.back-btn:hover {
  background-color: #2F316B;
}

#cmc .back-btn {
  background-color: var(--maincolor-cmc);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  color: #FFF !important;
  width: 75px;
  display: flex;
  justify-content: center;
  /* margin-left: 30px; */
}

#cmc .back-btn:hover {
  background-color: #046FA4;
}

/* HERO SECTION */
.home {
  height: 90vh;
  margin-top: 2vh;
}

.support {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

@media(max-width:768px) {
  .support {
    height: 100vh;
  }
}

.chooseEdsider {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 16px;
  gap: 16px;
}

.hero-section-container {
  margin-top: 50px;
}

.main-heading {
  color: var(--MAIN-CLR);
  font-family: var(--FF-MAINHEADING) !important;
  font-size: 8rem !important;
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: -0.1rem;
  /* line-height: 10rem; */
  margin-bottom: 0px !important;
}

@media (min-width:1800px) {
  .heroMainSec {
    margin-top: 20vh;
    padding: 0px !important;
    max-width: 1500px !important;
  }
}

@media (min-width:768px) and (max-width:1400px) {
  .heroMainSec {
    margin-top: 20vh;
  }
}

.sub-heading {
  color: var(--BLK-CLR);
  font-family: var(--FF-SECTIONHEADING) !important;
  font-size: 2.3rem;
  font-weight: 400;
  letter-spacing: -0.125rem;
  padding-bottom: 8px;
  margin: 0px !important;
}

.paragraph {
  color: #8290d8;
  font-family: var(--FF) !important;
  letter-spacing: -0.075rem;
  font-size: 2.5rem;
}

@media (max-width:1200px) {
  .paragraph {
    font-size: 1.5rem;
  }
}

.lang-text {
  font-size: 1.3rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-image {
  max-width: auto;
  max-height: 60vh;
  position: absolute;
}

/* IMAGE CAROUSAL */

.landing-carousal-img {
  width: 100%;
  display: flex;
}

/* SUPPORT SECTION */

.support-img {
  width: 14rem;
  height: auto;
}

.left-card-container {
  white-space: nowrap;
}

.image-container-end {
  display: flex;
  justify-content: flex-end;
}

.text-container {
  color: var(--FONT-COLOR);
  font-family: var(--FF-SECTIONHEADING);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0875rem;
}

.support-row-title {
  color: var(--FONT-COLOR);
  font-family: var(--FF-SECTIONHEADING);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.075rem;
}

.support-row-content {
  font-family: var(--FF) !important;
  font-size: 1.2rem;
  color: var(--FONT-COLOR);
  letter-spacing: -0.045rem;
}

.support-container {
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
  background-color: #fff;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 5px 0;
  padding: 10px;
}

.support-card {
  padding: 10px 20px;
}

.coming-soon-text {
  position: absolute;
  font-style: italic;
  transform-origin: top left;
  color: red;
  transform: rotate(-30deg);
  font-weight: 500;
  padding-top: 30px;
  z-index: 1;
  opacity: 0;
  animation: fade-in-out 2s ease-in-out infinite;
}

@keyframes fade-in-out {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.checkIcon {
  color: var(--DARKBLUE);
  margin-right: 5px;
}

/* GET STARTED SECTION */

.get-started-title {
  font-family: var(--FF-SECTIONHEADING);
  color: var(--FONT-COLOR);
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -0.0875rem;
}

.contact-text {
  color: #a1a1a1;
  font-family: var(--FF-SECTIONHEADING);
  font-size: 1.375rem;
  font-style: italic;
  margin-left: 25px;
  font-weight: 300;
}

.contact-icons {
  margin-left: 10px;
}

/* ABOUT US SECTION */

.bg-support-section {
  background-image: url(/static/media/about-bg.6ab595ab.png);
  background-size: cover;
  height: 650px;
}

.about-para {
  font-family: var(--FF) !important;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.045rem;
  color: #000000;
}

.card-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
  padding: 0 4rem;
  background-color: #fff;
  padding-bottom: 10px;
  margin-top: 30px;
}

.about-card-title {
  font-family: var(--FF-SECTIONHEADING);
  font-weight: 600;
  font-size: 3rem;
  letter-spacing: -0.1rem;
  padding-top: 40px;
  color: var(--FONT-COLOR);
}

.about-card-text {
  font-family: var(--FF) !important;
  font-size: 1.4rem;
  letter-spacing: -0.045rem;
  color: var(--FONT-COLOR);
}

@media (max-width:768px) {
  .about-card-title {
    padding-top: 20px;
  }
}

/* WHY CHOOSE EDSIDER */

.edsider-card {
  background-color: #fff;
  border-radius: var(--BORDER-RADIUS);
}

.edsider-img-container {
  padding: 40px 0;
}

.edsider-card-title {
  color: var(--FONT-COLOR);
  font-family: var(--FF-SECTIONHEADING);
  font-weight: 500;
  letter-spacing: -0.08rem;
  padding-bottom: 20px;
  font-size: 2.1rem;
}

.edsider-card-para {
  width: 20rem;
  font-size: 1.3rem;
  font-family: var(--FF) !important;
  font-weight: 400;
  letter-spacing: -0.045rem;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .edsider-card {
    min-height: 450px;
  }
}

/* TABS SECTION */

.tabs-container {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.tab-heading {
  font-family: var(--FF-SECTIONHEADING);
  font-weight: 500;
  font-size: 1.125rem;
}

.tab-content-row {
  padding: 20px;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
  min-height: 220px;
}

.tab-row-title {
  font-family: var(--FF-SECTIONHEADING);
  font-size: 1.625rem;
  color: var(--FONT-COLOR);
  font-weight: 500;
  letter-spacing: -0.08125rem;
  padding-bottom: 5px;
  border-bottom: gray 1px solid;
}

.tab-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-row-content {
  font-family: var(--FF) !important;
  color: var(--FONT-COLOR);
  letter-spacing: -0.045rem;
  font-weight: 400;
  font-size: 1.3rem;
}


@media (max-width: 768px) {
  .edsider-img-container {
    padding: 20px 0;
  }

  .edsiderImage {
    width: 80px;
  }
}

/* MODEL PAPERS SECTION */

.papers-row {
  padding: 30px 30px;
}

.papers-container {
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
  background-color: #fff;
  height: 10rem;
  margin: 5px 0;
  padding: 10px;
}

.papers-para {
  background-color: #fff;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
}

.papers-text {
  font-family: var(--FF) !important;
  font-size: 1.5rem;
  letter-spacing: -0.045rem;
  padding-left: 30px;
  line-height: normal;
}

.check-icon {
  width: 15px !important;
}

/* SCHOLARSHIP SECTION */

.bg-scholarship-section {
  background-image: url(/static/media/scholarship.07ca33bf.png);
  background-size: cover;
}

.schol-card-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17rem;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
}

.schol-card-title {
  color: var(--FONT-COLOR);
  font-size: 2rem;
}

/* TESTIMONIALS SECTION */

.arrow-icons {
  padding-top: 50px;
  padding-bottom: 20px;
}

.arrow-icons-left,
.arrow-icons-right {
  width: 60px;
  height: 60px;
  border-radius: var(--BORDER-RADIUS);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow-icons-left {
  background-color: #fff;
  margin-right: 20px;
}

.arrow-icons-right {
  background: var(--button-gradient,
      linear-gradient(90deg, #187de4 0%, #3d9bff 100%));
}

.testimonial-card {
  background-color: #fff;
  height: 18rem;
  padding: 40px 40px;
  border: var(--BORDER);
  border-radius: var(--BORDER-RADIUS);
}

.testimonial-title {
  font-family: var(--FF-SECTIONHEADING);
  color: var(--FONT-COLOR);
  font-size: 1.75rem;
  letter-spacing: -0.0875rem;
  font-weight: 500;
  /* padding-left: 20px; */
  margin: 0;
}

.testimonial-text {
  font-family: var(--FF) !important;
  color: var(--FONT-COLOR);
  font-size: 1.2rem;
  letter-spacing: -0.045rem;
  padding-top: 30px;
}

/* FOOTER */

.bg-cyan-section {
  background-color: #EDEEF7;
}

.footer-text {
  font-family: var(--FF) !important;
  font-size: 1.125rem;
  letter-spacing: -0.05625rem;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 40px;
}

.follow-text {
  font-family: var(--FF) !important;
  font-size: 1.125rem;
  letter-spacing: -0.05625rem;
  font-weight: 500;
  margin-bottom: 8px !important;
}

.social-icons {
  margin-left: 1px;
}

.fb-icon,
.twitter-icon,
.insta-icon,
.mail-icon {
  padding-right: 8px;
  cursor: pointer;
}

.footer-title {
  font-family: var(--FF) !important;
  font-size: 1.125rem;
  letter-spacing: -0.045rem;
  color: var(--FONT-COLOR);
  font-weight: 600;
  margin-left: 12px;
}

/* @media (min-width: 768px) {
.general-title {
  margin-left: 26px;
}
} */

.footer-content {
  color: var(--FONT-COLOR);
  font-size: 1rem;
  font-weight: 300;
  font-family: var(--FF-SECTIONHEADING);
  letter-spacing: -0.04375rem;
  cursor: pointer;
}

.footer-links {
  color: var(--FONT-COLOR);
}

.copyright-container {
  border-top: 1px solid var(--FONT-COLOR);
  font-family: var(--FF) !important;
  font-size: 1rem;
  padding: 10px 0;
  color: var(--FONT-COLOR);
  letter-spacing: -0.04375rem;
}

.policy-content {
  color: var(--FONT-COLOR);
}

/* MEDIA QUERIES */

@media (max-width: 420px) {
  .main-heading {
    font-size: 5rem !important;
  }

  .hero-section-container {
    margin-top: 20px;
  }

  .edsider-section-title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .tab-img-container {
    width: 100px;
    margin-bottom: 10px;
  }
}

@media (min-width: 421px) and (max-width: 576px) {

  .hero-section-container {
    margin-top: 20px;
  }

  .check-container {
    margin-left: 12px;
  }

  .navigation-list {
    display: flex;
  }

  .first-link-item {
    padding-top: 30px;
  }

  .main-heading {
    font-size: 6rem !important;
    z-index: 5;
  }

  .edsider-section-title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .action-links {
    margin-left: -5px;
  }

  .signin-btn,
  .register-btn {
    /* margin-left: 1.5rem; */
    color: var(--PRIMARYBLUE);
  }

  .register-btn {
    --button-default-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    --button-hover-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    padding: 0px 0px;
    text-align: start;
    margin-left: 24px;
    color: var(--PRIMARYBLUE);
  }

  .image-container {
    padding-top: 10px;
  }

  .support-left-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-card-container {
    display: flex;
    justify-content: center;
  }

  .support-title {
    padding-top: 0;
  }

  .support-container {
    height: 17rem;
  }

  .support-check {
    padding-bottom: 10px;
  }

  .text-container {
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
  }

  .card-container {
    margin-bottom: 2rem;
    height: 18rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }

  .bg-about-section {
    height: 780px;
  }

  .about-card-title {
    padding-top: 0;
    font-size: 2rem;
  }

  .edsider-card {
    margin-bottom: 20px;
  }

  .edsider-card-para {
    width: 20rem;
  }

  .tab-image-container {
    padding: 30px 0;
  }

  .tab-img-container {
    width: 30%;
    padding-bottom: 20px;
  }

  .papers-container {
    height: 15rem;
  }

  .schol-card-container {
    margin-bottom: 2rem;
    height: 10rem;
  }

  .arrow-icons-left {
    margin-right: 10px;
  }

  .footer-title {
    padding-top: 30px;
    margin-left: 0;
  }

  .footer-links {
    margin-left: 0;
  }

  .general-links {
    margin-left: -12px;
  }

  .f-action-links {
    margin-left: -13px;
  }

  .copyright-items {
    display: flex;
    justify-content: center;
  }

  .policy-content {
    display: flex !important;
    justify-content: center !important;
  }

}

@media (max-width:768px) {
  .tab-img-container {
    width: 120px;
  }

  .footer-title{
    margin-top: 16px !important;
  }

  .footer-text {
    padding-bottom: 20px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .main-heading {
    font-size: 8rem !important;
  }
}


@media (min-width: 1101px) and (max-width: 1200px) {
  .main-heading {
    font-size: 7rem !important;
  }
}

@media (min-width: 576px) and (max-width: 1100px) {
  .main-heading {
    font-size: 6rem !important;
    z-index: 5;
  }

  .action-links {
    margin-left: -5px;
  }

  .first-link-item {
    /* padding-top: 30px; */
  }

  .signin-btn,
  .register-btn {
    /* margin-left: 1.5rem; */
    color: var(--PRIMARYBLUE);
  }

  .register-btn {
    --button-default-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    --button-hover-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    padding: 0px 0px;
    text-align: start;
    margin-left: 24px;
    color: var(--PRIMARYBLUE);
  }

  .card-container {
    padding: 0 2rem;
  }

  .about-card-title {
    padding-top: 2rem;
  }

  .schol-card-container {
    /* padding-bottom: 2rem; */
  }

  .edsider-card-para {
    width: 15rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {

  .signin-btn,
  .register-btn {
    /* margin-left: 1.5rem; */
    color: var(--PRIMARYBLUE);
  }

  .register-btn {
    --button-default-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    --button-hover-color: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
    padding: 0px 0px;
    text-align: start;
    margin-left: 24px;
    color: var(--PRIMARYBLUE);
  }

  .schol-card-container {
    height: 10rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .useful-links {
    padding-left: 1rem;
  }
}

@media (max-width: 1440px) {
  .useful-links {
    padding-right: 6rem;
  }
}

.edaMain1 {
  z-index: 3;
}

.edaMain2 {
  z-index: 2;
}

.edaMain3 {
  z-index: 1;
}


.image-stack {
  position: relative;
  width: 100%;
}

.image-stack__item--top {
  position: absolute;
  right: 0;
  top: 10px;
  width: 60%;
  z-index: 0;
}

.image-stack__item--bottom {
  padding-top: 0px;
  padding-right: 20%;
  width: 80%;
}

.aboutImgs {
  width: auto;
  height: 250px;
}

@media (min-width: 910px) and (max-width:1300px) {
  .support-card {
    padding: 0px;
  }

  .support-row-content {
    font-size: 1rem;
  }
}

@media (max-width:768px) {
  .aboutImgs {
    display: none;
  }
}

.welcome-topic {
  color: #5458AF;
  font-family: 'poppins';
  font-weight: 500;
  font-size: 28px;
  position: relative;
  z-index: 1;
  line-height: 24px;
}

/* @media (max-width: 992px ) {
  .displayWeb {
    display: none;
  }
}

@media (min-width: 992px ) {
  .displayMobile {
    display: none;
  }
} */


/* Hero.css */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes bounce-in {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-50px);
  }

  60% {
    /* transform: translateY(-25px); */
  }
}

.main-heading {
  width: 9ch;
  /* 9 characters in "Study Zone" */
  animation: typing 3s steps(9, end) 0.5s 1 normal both;
  /* Steps based on number of characters */
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 8ch;
  }

  /* Matches the number of characters */
}


.sub-heading {
  animation: fadeIn 1s ease-in-out forwards, layDownGetUp 1s ease-in-out forwards;
}

@keyframes layDownGetUp {
  0% {
    transform: rotateX(90deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

@media (min-width: 769px) {
  .sub-heading {
    white-space: nowrap;
  }

  .paragraph {
    white-space: nowrap;
  }

  .mobileHeroImg {
    display: none;
  }
}

@media (max-width: 768px) {
  .heroImgs {
    display: none;
  }

  .centered-image {
    position: relative;
  }
}

.paragraph {
  animation: fadeIn 1s ease-in-out forwards, layDownGetUp 1s ease-in-out forwards;
}

.getstarted-btn {
  animation: bounce-in 1.5s;
}

.mobileHero {
  display: flex;
  justify-content: center;
}

.mobileHeroImg {
  height: 25vh;
  width: auto;
}

.image-container .centered-image {
  opacity: 0;
}

.image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.image-container .centered-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
}

.image-container .edaMain1 {
  animation: slideInFade 9s ease-in-out infinite;
}

.image-container .edaMain2 {
  animation: slideInFade 9s ease-in-out infinite;
  animation-delay: 3s;
}

.image-container .edaMain3 {
  animation: slideInFade 9s ease-in-out infinite;
  animation-delay: 6s;
}

@keyframes slideInFade {

  0%,
  100% {
    opacity: 0;
    transform: translateX(100%);
  }

  10%,
  30% {
    opacity: 1;
    transform: translateX(0);
  }

  40%,
  90% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Support.css */
.supTitle {
  animation: fadeInFromLeft 1s ease-in-out forwards;
}

@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fallAndBounce {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  60% {
    transform: translateY(20%);
  }

  80% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(0);
  }
}

.falling-image1 {
  animation: fallAndBounce 1.5s ease-out forwards;
}

@media (max-width: 910px) {

  .falling-image1 {
    margin-bottom: 15%;
    visibility: hidden
  }

  .image-stack__item--top {
    width: 100%;
  }

  .support-card {
    padding: 0px 5px;
  }
}


/* Initial state */
.support-container {
  opacity: 0;
  transform: translateX(200px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

/* Animate class for elements that enter the viewport */
.animate {
  opacity: 1;
  transform: translateX(0);
}

/* Animation delays for each support container */
.support-container.animation-delay-1 {
  transition-delay: 0.6s;
}

.support-container.animation-delay-2 {
  transition-delay: 1.2s;
}

.support-container.animation-delay-3 {
  transition-delay: 1.8s;
}


.support-row-title {
  font-weight: bold;
  /* font-size: 1.2rem; */
  margin-bottom: 0.5rem;
}

.support-row-content {
  /* font-size: 1rem; */
  line-height: 1.4;
}

/* ChooseEdsider.css */
@keyframes typingEffect {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .titlechooseEd {
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  animation: slideInLeft 1s ease-out forwards;
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: black;
  }
} */

/* Initial state */
.edsider-card {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* Animate class for elements that enter the viewport */
.animate {
  opacity: 1;
  transform: translateY(0);
}


@media (max-width:768px) {
  .edsider-card {
    margin: 10px;
  }
}

/* About.css */
@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes layEffect {
  from {
    opacity: 0;
    transform: rotate(90deg) translateY(-100%);
  }

  to {
    opacity: 1;
    transform: rotate(0deg) translateY(0);
  }
}

@keyframes standEffect {
  from {
    opacity: 0;
    transform: rotate(-90deg) translateY(-100%);
  }

  to {
    opacity: 1;
    transform: rotate(0deg) translateY(0);
  }
}

.about-title {
  opacity: 0;
  transform: translateY(-100%);
}

.about-para {
  opacity: 0;
}

.about-card {
  opacity: 0;
}

.aboutImgs {
  opacity: 0;
}

.animate {
  opacity: 1;
}

.about-title.animate {
  animation: slideInUp 1s ease-out forwards;
}

.about-para.animate {
  animation: slideInDown 1s ease-out forwards;
}

.left-to-right.animate {
  animation: slideInLeft 1s ease-out forwards;
}

.right-to-left.animate {
  animation: slideInRight 1s ease-out forwards;
}

.lay-effect.animate {
  animation: layEffect 1.5s ease-out forwards;
}

.stand-effect.animate {
  animation: standEffect 1.5s ease-out forwards;
}

/* Tabs.css */
@keyframes topToButtom {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttomToTop {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.up-to-down {
  opacity: 0;
  transform: translateY(-100%);
}

.down-to-up {
  opacity: 0;
  transform: translateY(100%);
}

.animate {
  opacity: 1;
}

.up-to-down.animate {
  animation: topToButtom 1s ease-out forwards;
}

.down-to-up.animate {
  animation: buttomToTop 1s ease-out forwards;
}
/* signup.css */

/* Global styles */
body {
    /* font-family: Arial, sans-serif; */
    margin: 0;
    padding: 0;
  }

  .navigation-bg {
    background-color: #FFF;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }
  
  /* Container styles */
  .login-edsider-background {
    background-color: #f2f2f2;
    /* min-height: 152vh; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url(/static/media/login-bg.7b596727.png);
    background-size: cover;
  }

  .login-cmc-background {
    background-color: #f2f2f2;
    /* min-height: 152vh; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url(/static/media/cmclogin-bg.c9185bec.svg);
    background-size: cover;
  }
  
  .container {
    padding-bottom: 5rem;
  }
  
  /* Card styles */
  .login-card {
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 30px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .login-card-title {
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  
  .login-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .request-form-para {
    text-align: justify;
  }

  .req-form-name {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  /* Form styles */
  form {
    width: 100%;
  }
  
  .form-group {
    margin-bottom: 2rem;
  }
  
  form label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  form input[type="text"],
  form input[type="email"] {
    font-family: "Poppins", "sans-serif";
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s ease;
  }
  
  form input[type="text"]:focus,
  form input[type="email"]:focus {
    outline: none;
    border-color: #7779CF;
    box-shadow: 0 0 0 3px rgba(102, 0, 255, 0.3);
  }
  
  .form-error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  .form-success-message {
    color: green;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  /* Button styles */
  button[type="submit"] {
    background-color: #5458af;
    color: #fff;
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #5458af;
  }
  
  /* Spinner styles */
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
  
  /* .spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
   */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .footer-container {
    font-size: 1rem;
    padding: 10px 0;
    color: #2C3440;
    letter-spacing: -0.04375rem;
    background-color: #FFF;
  }

  .informingMsg{
    font-weight: 500;
    color: rgb(89, 118, 223);
  }
:root {
  /* FONTS */
  --CMC-FF: "Darker Grotesque", sans-serif;
  --CMC-HEADING: 20rem;
  --CMC--SUBHEADING: 5rem;

  /* COLORS */
  --CMC-FONT: #191919;
  --CMC-GRADIENT: linear-gradient(to right,
      var(--DARKBLUE),
      var(--PRIMARYBLUE));
  --PURPLE: #9a7bff;
  --TURQUOISE: #4df4ff;
  --LIGHTGREEN: #e4f1ee;
  --LIGHTORANGE: #fff6e5;
  --LIGHTTURQUOISE: #e2fafd;
  --LIGHTBLUE: #e1e9ff;
}

img {
  pointer-events: none;
}

.cmc-landing {
  background-image: url(/static/media/cmc-bg.9fe50bc6.svg);
  background-color: #fff;
  background-size: cover;
  background-repeat: repeat-y;
}

.cmc-nav {
  border-bottom: 4px solid #fff;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 10px 40px 0px rgba(225, 233, 255, 0.25);
  z-index: 1;
  position: relative;
}

.cmc-links {
  color: var(--CMC-FONT);
  font-family: var(--CMC-FF) !important;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
}

.cmc-links:hover {
  background: linear-gradient(to right,
      var(--PURPLE),
      var(--PURPLE),
      #4679b3,
      #4679b3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cmc-hero-container {
  padding: 110px 0;
}

.cmc-heading {
  font-size: 72px;
  line-height: 70px;
  z-index: 10;
}

.cmc-para {
  font-family: var(--CMC-FF) !important;
  width: 400px;
  margin-top: 30px !important;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  z-index: 10;
}

.title-gradient {
  background: linear-gradient(to right,
      var(--PURPLE),
      var(--PURPLE),
      var(--TURQUOISE),
      var(--TURQUOISE));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cmc-signin-btn {
  background: linear-gradient(#fffffff5, #fffffff5) padding-box,
    linear-gradient(to right,
      var(--PURPLE),
      var(--PURPLE),
      var(--PRIMARYBLUE),
      var(--PRIMARYBLUE)) border-box;
  padding: 15px 30px;
  border: 2px solid transparent;
  border-radius: 5px;
  font-size: 20px;
  font-family: var(--CMC-FF) !important;
  font-weight: 600;
  color: #4d4077;
}

.cmc-register-btn {
  background: linear-gradient(90deg, var(--PURPLE) 0%, #3d9bff 100%);
  --button-hover-color: linear-gradient(90deg, #3480d1 0%, #7d64ce 100%);
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  font-family: var(--CMC-FF) !important;
  color: #fff;
  font-weight: 600;
}

.cmc-signin-btn:hover {
  background: linear-gradient(90deg, #3d9bff25 0%, #9a7bff25 100%);
}

.cmc-register-btn:hover {
  background: var(--button-hover-color)
}

.book-img {
  margin-right: -100px;
  z-index: 1;
}

.child-img-container {
  margin-top: -200px;
}

.child-img {
  margin-right: -285px;
  z-index: 2;
  margin-bottom: 10px;
}

.light-green-container {
  padding: 30px 0;
  background-color: var(--LIGHTGREEN);
  border-radius: 30px;
  margin-top: -55px;
  z-index: 1;
}

.arrow-one {
  margin-left: 60px;
  margin-top: -60px;
}

.arrow-two {
  z-index: 2;
  margin-left: -475px;
  margin-bottom: -70px;
}

/* CMC Sign In */

.signin-container {
  background-color: var(--LIGHTGREEN);
  margin-top: 30px;
  border-radius: 20px;
  z-index: 2;
}

.btn-register {
  background-color: #4a938d;
  color: #fff;
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
}

.btn-register:hover {
  background-color: #3b7570;
}

.signin-heading {
  font-family: var(--CMC-FF) !important;
  font-size: 50px;
  font-weight: 700;
}

.signin-para {
  font-family: var(--CMC-FF) !important;
  font-size: 18px;
}

.gradient-line {
  width: 150px;
  height: 10px;
  border-radius: 10px;
  background: linear-gradient(to right, #4a938d, #ffa700);
}

.parent-img {
  background-image: url(/static/media/parentimg.5637c533.svg);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-img{
  background-image: url(https://img.freepik.com/free-photo/portrait-pensive-young-girl-making-notes_171337-1646.jpg?t=st=1735307761~exp=1735311361~hmac=25f8497c043a3cf722bf0cf9ead68d4a3e7cdc90676504da07fc1d0d09976b26&w=996);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.student-img {
  background-image: url(/static/media/studentimg.6480cf0e.svg);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.admin-img {
  background-image: url(/static/media/admin-card-img.483e19bb.png);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.teacher-img {
  background-image: url(/static/media/teacher.9ae03ad7.svg);
  height: 253.04px;
  width: 380px;
  background-size: cover;
  background-repeat: no-repeat;
}

.button-container {
  padding: 20px 0px;
  background-color: #fff;
  width: 380px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.signin-btn-container {
  width: 300px;
  height: 45px;
  border-radius: 8px;
  background-color: #4a938d;
  border: none;
  color: #fff;
  font-size: 14px;
}

.signUp-mini-btn {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #4a938d;
  color: #fff;
  font-size: 12px;
}

.signin-btn-container:hover {
  background-color: #3b7570;
}

.signin-note {
  white-space: nowrap !important;
  margin-bottom: 4px;
}

.signin-note-inside {
  margin: 0px 16px;
  cursor: pointer;
}

.signin-note-inside:hover {
  color: rgb(71, 71, 249);
}

@media (max-width: 767px) {
  .cmc-logo {
    width: 200px !important;
  }

  .register-btn-container {
    padding-right: 0rem !important;
  }

  .cmc-heading {
    font-size: 50px;
    line-height: 55px;
  }

  .cmc-para {
    width: 300px;
  }

  .cmc-links {
    font-size: 12px;
  }

  .title-underline {
    width: 160px !important;
  }

  .book-img {
    display: none;
  }

  .child-img-container {
    padding-top: 175px;
    display: flex !important;
    justify-content: center !important;
  }

  .child-img {
    margin-right: 0;
  }

  .light-green-container {
    margin-top: 50px;
  }

  .group-img {
    width: 350px !important;
  }

  .arrow-one,
  .arrow-two {
    display: none;
  }

  /* CMC Sign In */

  .no-account-text {
    display: none;
  }

  .vector-one,
  .vector-two {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .book-img {
    margin-right: -45px;
    width: 150px !important;
    margin-top: 100px;
  }

  .child-img {
    width: 400px !important;
  }

  .child-img-container {
    margin-top: 0px;
    display: flex !important;
    justify-content: center !important;
  }

  .child-img {
    margin-right: 0px;
  }

  .light-green-container {
    margin-top: 30px;
  }

  .arrow-one {
    margin-top: -130px;
    margin-left: -80px;
  }

  .arrow-two {
    margin-left: -220px;
    margin-top: 240px;
  }

  /* CMC Sign In */

  .parent-img {
    background-image: url(/static/media/parentimg.5637c533.svg);
    height: 174px;
    width: 261px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .student-img {
    background-image: url(/static/media/studentimg.6480cf0e.svg);
    height: 174px;
    width: 261px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .button-container {
    padding: 20px 0px;
    background-color: #fff;
    width: 261px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .signin-btn-container {
    width: 220px;
    height: 45px;
    border-radius: 8px;
    background-color: #4a938d;
    border: none;
    color: #fff;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1440px) {
  .cmc-landing {
    /* height: 100vh; */
  }
}
/* .royal-landing-bg {
  height: 100vh;
  background-position: center top;
  background-size: cover;
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-color: black;
} */

.royal-landing-bg  {
  background-image: url(/static/media/royal-bg.c4af9750.png);
  background-size: cover;
  background-repeat: repeat-y;
  background-color: black;
  height: 110lvh;
}

.royal-logo-img {
  max-width: 200px;
  max-height: 95px;
}

@media (min-width: 576px) and (max-width: 1024px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 120vh;
  }
}

/*desktops - higher resolution*/
@media (max-width: 1281px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 120vh;
  }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 130vh;
  }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
  }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
  }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 180vh;
  }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 180vh;
  }
}

@media (max-width: 319px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 180vh;
  }
}

:root {
  --primaryCover-color: #133461;
  --secondaryCover-color: #BEC7CF;
  --thirdyCover-color: #ffffff;
}

.body {
  background-color: white;
  margin: 20px 100px !important;
}

@media only screen and (max-width: 600px) {
  .body {
    margin: 20px !important;
  }
}

.headerBar {
  background-color: var(--primaryCover-color);
  height: 20px;
}

.MuiGrid-root {
  padding: 0px !important;
  margin: 0px !important;
}

.personIcon {
  background-color: var(--secondaryCover-color);
  margin: 5px;
  border-radius: 50px;
  height: 40px;
  width: 40px;
}

.PIcon {
  height: 20px !important;
  width: 20px !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alignItemsCenter {
  display: flex;
  align-items: center;
}

.alignItemsRight {
  display: flex;
  justify-content: end;
}

.alignItemsLeft {
  display: flex;
  justify-content: start;
}

.justifyContentCenter {
  display: flex;
  justify-content: center;
}

.justifyContentLeft {
  display: flex;
  justify-content: start;
}

.justifyContentRight {
  display: flex;
  justify-content: end;
}

.button1 {
  background-color: var(--primaryCover-color) !important;
  height: 50px;
  margin-left: 10px !important;
  font-family: Poppins, Helvetica, sans-serif !important;
  font-weight: 500;
  text-transform: none !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column !important;
}

.links {
  background-image: linear-gradient(to right, #133461, #CED8E5);
  border-left: 5px solid var(--secondaryCover-color);
  color: var(--thirdyCover-color);
  border-radius: 5px;
  padding: 10px !important;
  margin-bottom: 20px !important;
}

.link {
  background-image: linear-gradient(to right, #cdd5df, #f4f8fc);
  border-left: 5px solid var(--primaryCover-color);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  margin-right: 5px;
  width: 95%;
}

.study {
  height: 100px;
  margin: 0px 20px;
}

.cursive {
  font-family: 'Birthstone', cursive;
  font-size: 24px;
}

.menu_board {
  height: 16px;
  margin-right: 10px;
}

.menu_board_blue {
  height: 16px;
  margin-right: 10px;
}

.cgclogo {
  width: 80px;
}

.welcome {
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
}

.text01 {
  font-size: 16px;
  color: var(--primaryCover-color);
}

.text02 {
  font-size: 28px;
  color: var(--primaryCover-color);
  margin: 20px;
}

.text03 {
  font-size: 12px;
  color: var(--primaryCover-color);
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
}

.text04 {
  font-size: 12px;
  color: #2F80ED;
  margin: 5px 0px;
  cursor: pointer;
}

.hr {
  border-bottom: 1px solid var(--secondaryCover-color);
  margin: 30px 0px;
}

.hr2 {
  border-bottom: 1px solid var(--secondaryCover-color);
  margin: 2px 10px;
  width: 80%;
}

.sets {
  border: 1px solid var(--secondaryCover-color);
  border-radius: 5px;
  margin: 10px !important;
}

.courses {
  border-left: 5px solid #5500FF;
  background-color: #E1D2FF;
  border-radius: 5px;
  margin: 5px !important;
  grid-gap: 5px;
  gap: 5px;
}

.announcement {
  border-radius: 5px;
  margin: 5px !important;
  grid-gap: 10px;
  gap: 10px;
}

.ann1 {
  border: 2px solid #FEB446;
}

.ann2 {
  border: 1px solid #F18A8A;
}

.ann_body{
font-size: 10px;
font-weight: 500;
}

.announcementTopic{
  font-size: 14px;
  border-bottom: 3px;
}

.ann_date{
  font-size: 8px;
  font-weight: 500;
}

.chip {
  border-radius: 2px;
  padding: 2px;
  font-size: 8px;
}

.chip1 {
  background-color: #FFEBDB;
  color: #FEB446;
  margin-left: 10px;
}

.chip2 {
  background-color: #FBDDDD;
  color: #EB5757;
}

.edu {
  margin: 5px;
}

.cover {
  width: 100% !important;
}

@media only screen and (min-width: 1600px) {
  .text01 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1400px) {
  .text01 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .topbar {
    height: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .personIcon {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .text01 {
    font-size: 8px;
  }

  .text02 {
    font-size: 12px;
  }

  .link {
    width: 100%;
  }

  .study {
    height: 50px;
    margin: 0px 10px;
  }

  .button1 {
    height: 30px;
  }

  .btntxt{
    font-size: 8px;
  }

  .hand{
    height: 10px;
  }
}
.custom-calendar .rbc-month-view {
  border: none;
}

.custom-calendar .rbc-header {
  border-bottom: none;
}

.custom-calendar .rbc-row-bg {
  border-top: none;
}

.custom-calendar .rbc-time-header {
  border-right: none;
}

/* siteannouncement.css */

.custom-header {
  background-color: #5458af;
  color: #fff;
}

/* progressbar.css */
.custom-progress-bar .progress-bar {
  background-color: #5458af;
}

/* tiles.css */
.DashboardTiles {
  margin: 20px;
}

.tilePaper-dashboard {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tileTitle-dashboard {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.tileContent-dashboard {
  display: flex;
  align-items: center;
}

.tileIcon-dashboard {
  margin-right: 10px;
}

.tileCount-dashboard {
  font-size: 1.5rem;
  font-weight: bold;
}

/* 
Best-selling-courses.css */

.best-selling-courses {
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.best-selling-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.best-selling-th {
  background-color: #5458af;
  color: #fff;
}

.best-selling-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.best-selling-table th {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.best-selling-table tr:hover {
  background-color: #d9daf1;
}

/* .chart-container {
overflow-x: auto;
width: 100%;
margin-bottom: 20px; 
} */

/* managedashboard.css */
.scrollable-chart {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
}

.progress-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
}

.doughnutchart-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
}

.coursetable-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: 380px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.attendance-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.drilldown-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.doughnutchart1-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: 380px;
  padding-top: 20px;
}

/* drillchart.css */

.content-drillchart th,
.content-drillchart td {
  border: 1px solid #d9daf1;
  padding: 8px;
  text-align: left;
}

.content-drillchart th {
  background-color: #fffdfd;
}

.content-drillchart tr:hover {
  background-color: #d9daf1;
}

.content-drillchart.level-1 tr {
  background-color: #e9e9f5;
}

.content-drillchart.level-2 tr {
  background-color: #e9e9f5;
}

.content-drillchart.level-3 tr {
  background-color: #e9e9f5;
}

.container-drillchart {
  border: 1px solid #ccc;
  height: 300px;
  max-width: 600px;
  padding: 10px;
  margin: 20px auto;
  background-color: #d9dbf1;
  border-radius: 5px;
  overflow-y: auto;
}

.content-drillchart {
  margin-top: 6px;
  margin-bottom: 10px;
  border-collapse: collapse;
  width: 100%;
}

.top-right-buttons-drillchart {
  position: absolute;
  top: 0;
  right: 10px;
  margin: 10px;
}

.top-right-buttons-drillchart button {
  color: #ffffff;
  background-color: #545cb4;
  border: none;
  border-radius: 5px;
  padding: 10px 12px;
  margin-right: 5px;
  cursor: pointer;
}

/* 
.row .content-drillchart:hover {
background-color: #f0f0f0;
}
.content-drillchart.level-1 .row {
background-color: #f0f0f0; 
}

.content-drillchart.level-2 .row {
background-color: #e0e0e0;
}

.content-drillchart.level-3 .row {
background-color: #d0d0d0; 
}

.content-drillchart .row:hover {
background-color: #f5f5f5; 
}  */

/* 
attendance.css */

.attendence-chart {
  margin-top: 50px;
}

/* notice-board.css */
.notice-board {
  margin: 10px;
}

#notice-board-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
}

#notice-card-header {
  background-color: #5458af;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

#notice-header-title {
  margin: 0;
  color: white;
}

#notice-card-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.sender-info {
  flex-grow: 1;
}

.sender-name {
  font-weight: bold;
  margin: 0;
}

.sender-role {
  margin: 0;
}

.notice {
  margin: 0;
}

.quickguide-container {
  padding: 30px 0px;
  border: 1px solid #c8c9f9;
  border-left: 8px solid #7779cf;
  border-radius: 5px;
}

.quickguide-button {
  border: 1px solid #c8c9f9;
  padding: 5px 15px;
  background-color: #f9f9ff;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  margin-top: 5px;
  font-size: 12px;
  color: var(--maincolor);
  font-weight: 500;
}

/* New Student Dashboard */

.dashboard__welcome {
  background-color: #f9f9ff;
  border: 1px solid #c8c9f9;
  border-radius: 5px;
  padding: 10px 15px;
}

.dashboard__welcome-text {
  font-weight: 500;
  color: var(--maincolor);
  font-size: 18px;
}

.dashboard__container {
  border: 2px solid #edeff7;
  border-radius: 5px;
  padding: 10px 15px;
}

.dashboard__container-title {
  display: block;
  color: #303030;
  font-size: 14px;
  font-weight: 600;
}

.dashboard__container-text {
  font-size: 10px;
  color: #5f6276;
}

.announcement__card {
  border-top: 1px solid #edeef7;
  border-bottom: 1px solid #edeef7;
  padding: 12px 0px;
}

.announcement__title,
.document__title {
  font-weight: 500;
  font-size: 13px;
}

.document__icon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: #f0f0ff;
}

.announcement__breadcrumb,
.document__breadcrumb {
  font-size: 8px !important;
  font-weight: 500 !important;
}

.announcement__content,
.document__content {
  font-size: 10px;
}

.dashboard__count-cards-pink {
  background-image: linear-gradient(to right, #d53364, #f249a4);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #ffcedd;
}

.dashboard__count-cards-blue {
  background-image: linear-gradient(to right, #7f83db, #84c1e1);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #edeef7;
}

.dashboard__count-cards-green {
  background-image: linear-gradient(to right, #4aa96d, #7dc3ab);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #ceffee;
}

.dashboard__count-cards-purple {
  background-image: linear-gradient(to right, #7665a8, #ad569b);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #d7caff;
}

.dashboard__count-cards-orange {
  background-image: linear-gradient(to right, #f17a41, #ffce60);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #fce5af;
}

.dashboard__count-cards-dodger {
  background-image: linear-gradient(to right, #238bce, #00c6ff);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #c6f3ff;
}

.dashboard__user-text {
  color: #fff;
  font-weight: normal;
  font-size: 10px;
}

.dashboard__user-count {
  color: #fff;
}

.chart-container {
  flex-wrap: wrap;
}

/* Receivables */
.receivables-row {
  padding: 8px 10px;
  border-bottom: 1.2px solid #edeff7;
}
.receivables-title {
  color: #939393;
  font-size: 12px;
  font-weight: 500;
}

.receivables-amount {
  font-weight: bold;
}

/* Revenue */
.revenue-container {
  background-image: linear-gradient(to right, #f17a41, #ffce60);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #fce5af;
}

.revenue-text {
  color: #fff;
  font-size: 10px;
}

.revenue-amount {
  color: #fff;
}

.revenue__icon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 207, 96, 0.2);
}

.dashboard-subheading {
  font-size: 12px;
  color: #2d2d2d;
  font-weight: 600;
}

.revenue-row-text {
  font-size: 12px;
}

/* Payment Overdue */
.payment-overdue-container {
  background-color: #FAFBFC;
  border-radius: 5px;
  border-left: 4px solid #238bce;
}

.reminder-text {
  font-size: 10px;
  color:  #A2A5B5;
}
/* .royal-landing-bg {
  height: 100vh;
  background-position: center top;
  background-size: cover;
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-color: black;
} */

.royal-landing-bg  {
  background-image: url(/static/media/royal-bg.c4af9750.png);
  background-size: cover;
  background-repeat: repeat-y;
  background-color: black;
  height: 110lvh;
}

.royal-logo-img {
  max-width: 200px;
  max-height: 95px;
}

@media (min-width: 576px) and (max-width: 1024px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 120vh;
  }
}

/*desktops - higher resolution*/
@media (max-width: 1281px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 120vh;
  }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 130vh;
  }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
  }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
  }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 180vh;
  }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 180vh;
  }
}

@media (max-width: 319px) {
  .royal-landing-bg {
    background-image: url(/static/media/royal-bg.c4af9750.png);
    height: 180vh;
  }
}

/* Updated CSS */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.container-terms {
  margin: 50px auto;
  width: 70%;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f7f7f7;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333333;
}

.contents {
  margin-bottom: 20px;
  color: #666666;
  text-align: justify;
  line-height: 1.6;
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: #333333;
}

.checkbox-custom {
  /* appearance: none; */
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s;
  vertical-align: middle;
}

.checkbox-custom:checked {
  border-color: #0056b3;
  background-color: #0056b3;
}

.checkbox-text {
  font-weight: 500;
  vertical-align: middle;
}

.language-label {
  display: block;
  margin-bottom: 10px;
  color: #333333;
  font-weight: 500;
  text-align: right;
}

.language-select {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  color: #333333;
  font-size: 14px;
  width: 10%;
  vertical-align: middle;
}

/* Adjusted dropdown arrow */
.language-select:after {
  content: "\25BE";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, opacity 0.3s;
}

.submit-button:disabled,
.submit-button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .container-terms {
    width: 90%;
  }

  .language-label {
    text-align: left;
  }

  .language-select {
    width: 100%;
  }
}

.submit-button-back {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ccc;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, opacity 0.3s;
  align-self: flex-start; 
  margin-bottom: 10px; 
}

.submit-button-back:hover {
  background-color: #999; 
}

/* Updated CSS */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.container-terms {
  margin: 50px auto;
  width: 70%;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f7f7f7;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333333;
}

.contents {
  margin-bottom: 20px;
  color: #666666;
  text-align: justify;
  line-height: 1.6;
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: #333333;
}

.checkbox-custom {
  /* appearance: none; */
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s;
  vertical-align: middle;
}

.checkbox-custom:checked {
  border-color: #0056b3;
  background-color: #0056b3;
}

.checkbox-text {
  font-weight: 500;
  vertical-align: middle;
}

.language-label {
  display: block;
  margin-bottom: 10px;
  color: #333333;
  font-weight: 500;
  text-align: right;
}

.language-select {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  color: #333333;
  font-size: 14px;
  width: 10%;
  vertical-align: middle;
}

/* Adjusted dropdown arrow */
.language-select:after {
  content: "\25BE";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, opacity 0.3s;
}

.submit-button:disabled,
.submit-button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .container-terms {
    width: 90%;
  }

  .language-label {
    text-align: left;
  }

  .language-select {
    width: 100%;
  }
}

.submit-button-back {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ccc;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, opacity 0.3s;
  align-self: flex-start; 
  margin-bottom: 10px; 
}

.submit-button-back:hover {
  background-color: #999; 
}

/* signup.css */

/* Global styles */
body {
    /* font-family: Arial, sans-serif; */
    margin: 0;
    padding: 0;
  }

  .navigation-bg {
    background-color: #FFF;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }
  
  /* Container styles */
  .login-edsider-background {
    background-color: #f2f2f2;
    /* min-height: 152vh; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url(/static/media/login-bg.7b596727.png);
    background-size: cover;
  }

  .login-cmc-background {
    background-color: #f2f2f2;
    /* min-height: 152vh; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url(/static/media/cmclogin-bg.c9185bec.svg);
    background-size: cover;
  }
  
  .container {
    padding-bottom: 5rem;
  }
  
  /* Card styles */
  .login-card {
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 30px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .login-card-title {
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  
  .login-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .request-form-para {
    text-align: justify;
  }

  .req-form-name {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  /* Form styles */
  form {
    width: 100%;
  }
  
  .form-group {
    margin-bottom: 2rem;
  }
  
  form label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  form input[type="text"],
  form input[type="email"] {
    font-family: "Poppins", "sans-serif";
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s ease;
  }
  
  form input[type="text"]:focus,
  form input[type="email"]:focus {
    outline: none;
    border-color: #7779CF;
    box-shadow: 0 0 0 3px rgba(102, 0, 255, 0.3);
  }
  
  .form-error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  .form-success-message {
    color: green;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  /* Button styles */
  button[type="submit"] {
    background-color: #5458af;
    color: #fff;
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #5458af;
  }
  
  /* Spinner styles */
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
  
  /* .spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
   */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .footer-container {
    font-size: 1rem;
    padding: 10px 0;
    color: #2C3440;
    letter-spacing: -0.04375rem;
    background-color: #FFF;
  }

.container-drillchart {
    border: 1px solid #ccc;
    height: 300px;
    max-width: 600px;
    padding: 10px;
    margin: 50px auto;
    background-color: #D9DBF1;
    border-radius: 5px;
    overflow-y: auto;
}

.content-drillchart {
    margin-left: 20px;
    margin-top: 10px;
    /* Add gap at the top */
    margin-bottom: 10px;
}

.top-right-buttons {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
}

/* Figure Chart */

.figure-chart-container {
    border: 2px solid #edeef7;
    border-radius: 5px;
    padding: 10px 5px;
}

.figure-chart-text {
    font-size: 16px !important;
}

.multiBar {
    height: 70vh !important;
    font-family: "Poppins", sans-serif !important;
}

@media (max-width:768px) {
    .dropdownToggle {
        width: 75vw !important;
    }

    .groupedBarchart {
        display: none;
    }
}

.progress {
    margin: 6px 0px 16px 0px;
}
h1,
h2,
h3,
h4,
h5,
p,
label,
th,
td,
input,
select,
MenuItem {
    font-family: "Poppins", sans-serif !important;
}


/* siteannouncement.css */

.custom-header {
    background-color: var(--maincolor);
    color: #fff;
}

#cmc .custom-header {
    background-color: var(--maincolor-cmc);
    color: #fff;
}

/* progressbar.css */
.custom-progress-bar .progress-bar {
    background-color: var(--maincolor);
}

#cmc .custom-progress-bar .progress-bar {
    background-color: var(--maincolor-cmc);
}



/* tiles.css */
.DashboardTiles {
    margin: 20px;
}

.tilePaper-dashboard {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tileTitle-dashboard {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.tileContent-dashboard {
    display: flex;
    align-items: center;
}

.tileIcon-dashboard {
    margin-right: 10px;
}

.tileCount-dashboard {
    font-size: 1.5rem;
    font-weight: bold;
}




/* 
Best-selling-courses.css */

.best-selling-courses {
    margin: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.best-selling-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.best-selling-th {
    background-color: var(--maincolor);
    color: #fff;
}

#cmc .best-selling-th {
    background-color: var(--maincolor-cmc);
    color: #fff;
}

.best-selling-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.best-selling-table th {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.best-selling-table tr:hover {
    background-color: #D9DAF1;
}

/* .chart-container {
overflow-x: auto;
width: 100%;
margin-bottom: 20px; 
} */



/* managedashboard.css */
.scrollable-chart {
    display: inline-block;
    overflow-x: auto;
    width: 100%;
}

.progress-card {
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
}

.doughnutchart-card {
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
    /* height: 400px; */
    /* width: auto; */
    padding-top: 20px;
}

.coursetable-card {
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
    height: 380px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

}

.attendance-card {
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
    height: auto;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

}

.drilldown-card {
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
    height: auto;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

}

.doughnutchart1-card {
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
    height: 380px;
    padding-top: 20px;

}


/* drillchart.css */


.content-drillchart th,
.content-drillchart td {
    border: 1px solid #D9DAF1;
    padding: 8px;
    text-align: left;
}

.content-drillchart th {
    background-color: #fffdfd;
}

.content-drillchart tr:hover {
    background-color: #D9DAF1;
}

.content-drillchart.level-1 tr {
    background-color: #e9e9f5;
}

.content-drillchart.level-2 tr {
    background-color: #e9e9f5;
}

.content-drillchart.level-3 tr {
    background-color: #e9e9f5;
}

.container-drillchart {
    border: 1px solid #ccc;
    height: 300px;
    max-width: 600px;
    padding: 10px;
    margin: 20px auto;
    background-color: #D9DBF1;
    border-radius: 5px;
    overflow-y: auto;
}

.content-drillchart {
    margin-top: 6px;
    margin-bottom: 10px;
    border-collapse: collapse;
    width: 100%;
}


.top-right-buttons-drillchart {
    position: absolute;
    top: 0;
    right: 10px;
    margin: 10px;
}

.top-right-buttons-drillchart button {
    color: #ffffff;
    background-color: #545cb4;
    border: none;
    border-radius: 5px;
    padding: 10px 12px;
    margin-right: 5px;
    cursor: pointer;
}

#cmc .top-right-buttons-drillchart button {
    color: #ffffff;
    background-color: var(--maincolor-cmc);
    border: none;
    border-radius: 5px;
    padding: 10px 12px;
    margin-right: 5px;
    cursor: pointer;
}



/* 
.row .content-drillchart:hover {
background-color: #f0f0f0;
}
.content-drillchart.level-1 .row {
background-color: #f0f0f0; 
}

.content-drillchart.level-2 .row {
background-color: #e0e0e0;
}

.content-drillchart.level-3 .row {
background-color: #d0d0d0; 
}

.content-drillchart .row:hover {
background-color: #f5f5f5; 
}  */




/* 
attendance.css */

.attendence-chart {
    margin-top: 50px;
}

/* notice-board.css */
.notice-board {
    margin: 10px;
}

#notice-board-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #cccccc;
    border-radius: 8px;
    overflow: hidden;
}

#notice-card-header {
    background-color: var(--maincolor);
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

#cmc #notice-card-header {
    background-color: var(--maincolor-cmc);
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

#notice-header-title {
    margin: 0;
    color: white;
}

#notice-card-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.profile-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.sender-info {
    flex-grow: 1;
}

.sender-name {
    font-weight: bold;
    margin: 0;
}

.sender-role {
    margin: 0;
}

.notice {
    margin: 0;
}

/* Parent Dashboard */
.parent-section-title {
    font-family: 'Poppins', sans-serif !important;
    color: var(--maincolor);
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 24px;
    letter-spacing: -0.5px;
}

#cmc .parent-section-title {
    font-family: 'Poppins', sans-serif !important;
    color: var(--maincolor-cmc);
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 24px;
    letter-spacing: -0.5px;
}

.announcement-container {
    border: 1px solid #EDEEF7;
    border-radius: 10px;
}

.announcement-card {
    border: 2px solid #EDEEF7;
    padding: 20px 5px;
    border-radius: 10px;
}

#cmc .announcement-container {
    border: 1px solid #E8F6FF;
    border-radius: 10px;
}

#cmc .announcement-card {
    border: 2px solid #E8F6FF;
    padding: 20px 5px;
    border-radius: 10px;
}

.announcement-title {
    font-size: 16px;
}

.announcement-content {
    font-size: 10px;
}

.child-view-icon {
    padding: 4px;
    border-radius: 5px;
    margin-right: 8px;
    background-color: var(--maincolor);
    border: none;
}

#cmc .child-view-icon {
    padding: 4px;
    border-radius: 5px;
    margin-right: 8px;
    background-color: var(--maincolor-cmc);
    border: none;
}

.child-dropdown {
    color: #5F6276;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
}

/* Admin Dashboard */
.view-more {
    color: #545cb4;
    font-size: 10px;
    font-weight: 600;
    padding-bottom: 15px;
    cursor: pointer;
}

#cmc .view-more {
    color: var(--maincolor-cmc);
    font-size: 10px;
    font-weight: 600;
    padding-bottom: 15px;
    cursor: pointer;
}

.users-container {
    background-color: #EDEEF7;
    border-radius: 10px;
    padding: 20px 0;
    /* height: 160px; */
}

#cmc .users-container {
    background-color: #F2FAFF;
    border-radius: 10px;
    padding: 20px 0;
    /* height: 160px; */
}

.user-cards {
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 0;
    border: 3px solid #EDEEF7;
}

#cmc .user-cards {
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 0;
    border: 3px solid #E8F6FF;
}

.user-card-value {
    font-size: 24px;
    font-weight: 600;
    padding-top: 5px;
    color: #231F20;
}

.dashboard-top-container {
    background-color: #edeef7;
    border-radius: 10px;
    padding: 10px 0;
}

#cmc .dashboard-top-container {
    background-color: #F2FAFF;
    border-radius: 10px;
    padding: 10px 0;
}

.dashboard-bottom-container {
    border: 1px solid #edeef7;
    border-radius: 10px;
}

#cmc .dashboard-bottom-container {
    border: 1px solid #E8F6FF;
    border-radius: 10px;
}

.section-title {
    font-family: "Poppins", sans-serif !important;
    color: var(--maincolor);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
}

#cmc .section-title {
    font-family: "Poppins", sans-serif !important;
    color: var(--maincolor-cmc);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
}

.dashboard-cards {
    background-color: #fff;
    border-radius: 10px;
    border: 5px solid #edeef7;
}

#cmc .dashboard-cards {
    background-color: #fff;
    border-radius: 10px;
    border: 5px solid #E8F6FF;
}

.card-value {
    font-size: 24px;
    font-weight: 600;
    padding-top: 5px;
    color: #231f20;
}

/* .child-view-icon {
  padding: 4px;
  border-radius: 5px;
  margin-right: 8px;
  background-color: var(--maincolor);
  border: none;
} */

.view-more {
    color: #545cb4;
    font-size: 10px;
    font-weight: 600;
    cursor: pointer;
}

#cmc .view-more {
    color: var(--maincolor-cmc);
    font-size: 10px;
    font-weight: 600;
    cursor: pointer;
}

/* svg,
a {
  color: #545cb4;
} */

.announcement-title {
    font-size: 16px;
}

.announcement-content {
    font-size: 10px;
}

.announcement-card {
    border: 2px solid #EDEEF7;
    padding: 20px 5px;
    border-radius: 10px;
}

#cmc .announcement-card {
    border: 2px solid #E8F6FF;
    padding: 20px 5px;
    border-radius: 10px;
}

/* CMC and EdSider quick guide */

.quickguide-container {
    border: 1px solid #c8c9f9;
    border-left: 8px solid #7779CF;
    border-radius: 5px;
}

#cmc .quickguide-container {
    border: 1px solid #a3e6ff;
    border-left: 8px solid #a3e6ff;
    border-radius: 5px;
}

.quickguide-heading {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    padding-left: 15px;
    font-weight: 600;
}

#cmc .quickguide-heading {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    padding-left: 15px;
    font-weight: 600;
}

.quickguide-content {
    border: 1px solid #c8c9f9;
    background-color: #f5f5ff;
    border-radius: 5px;
    cursor: pointer;
}

#cmc .quickguide-content {
    border: 1px solid #b3edff;
    background-color: #eafaff;
    border-radius: 5px;
    cursor: pointer;
}

.quickguide-text {
    color: var(--maincolor);
    font-weight: 500;
}

#cmc .quickguide-text {
    color: var(--maincolor-cmc);
    font-weight: 500;
}

.steps-label {
    padding: 5px 10px;
    background-color: #f5f5ff;
    color: var(--maincolor);
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #c8c9f9;
}

#cmc .steps-label {
    padding: 5px 10px;
    background-color: #eafaff;
    color: var(--maincolor-cmc);
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #b3edff;
}

.steps-btn {
    background-color: var(--maincolor);
    padding: 5px 10px;
    border-radius: 3px;
    color: #fff;
}

#cmc .steps-btn {
    background-color: var(--maincolor-cmc);
    padding: 5px 10px;
    border-radius: 3px;
    color: #fff;
}

.steps-content-text {
    font-size: 12px;
    padding: 10px 0px;
}

@media screen and (max-width: 1057px) {
    .congratulations-img-left,
    .congratulations-img-right {
        display: none;
    }
}

#cmc .steps-content-text {
    font-size: 12px;
    padding: 10px 0px;
}
/* siteannouncement.css */

.custom-header {
  background-color: #5458af;
  color: #fff;
}

/* progressbar.css */
.custom-progress-bar .progress-bar {
  background-color: #5458af;
}

/* tiles.css */
.DashboardTiles {
  margin: 20px;
}

.tilePaper-dashboard {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tileTitle-dashboard {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.tileContent-dashboard {
  display: flex;
  align-items: center;
}

.tileIcon-dashboard {
  margin-right: 10px;
}

.tileCount-dashboard {
  font-size: 1.5rem;
  font-weight: bold;
}

/* 
Best-selling-courses.css */

.best-selling-courses {
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.best-selling-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.best-selling-th {
  background-color: #5458af;
  color: #fff;
}

.best-selling-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.best-selling-table th {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.best-selling-table tr:hover {
  background-color: #d9daf1;
}

/* .chart-container {
overflow-x: auto;
width: 100%;
margin-bottom: 20px; 
} */

/* managedashboard.css */
.scrollable-chart {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
}

.progress-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
}

.doughnutchart-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
}

.coursetable-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: 380px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.attendance-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.drilldown-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.doughnutchart1-card {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  height: 380px;
  padding-top: 20px;
}

/* drillchart.css */

.content-drillchart th,
.content-drillchart td {
  border: 1px solid #d9daf1;
  padding: 8px;
  text-align: left;
}

.content-drillchart th {
  background-color: #fffdfd;
}

.content-drillchart tr:hover {
  background-color: #d9daf1;
}

.content-drillchart.level-1 tr {
  background-color: #e9e9f5;
}

.content-drillchart.level-2 tr {
  background-color: #e9e9f5;
}

.content-drillchart.level-3 tr {
  background-color: #e9e9f5;
}

.container-drillchart {
  border: 1px solid #ccc;
  height: 300px;
  max-width: 600px;
  padding: 10px;
  margin: 20px auto;
  background-color: #d9dbf1;
  border-radius: 5px;
  overflow-y: auto;
}

.content-drillchart {
  margin-top: 6px;
  margin-bottom: 10px;
  border-collapse: collapse;
  width: 100%;
}

.top-right-buttons-drillchart {
  position: absolute;
  top: 0;
  right: 10px;
  margin: 10px;
}

.top-right-buttons-drillchart button {
  color: #ffffff;
  background-color: #545cb4;
  border: none;
  border-radius: 5px;
  padding: 10px 12px;
  margin-right: 5px;
  cursor: pointer;
}

/* 
.row .content-drillchart:hover {
background-color: #f0f0f0;
}
.content-drillchart.level-1 .row {
background-color: #f0f0f0; 
}

.content-drillchart.level-2 .row {
background-color: #e0e0e0;
}

.content-drillchart.level-3 .row {
background-color: #d0d0d0; 
}

.content-drillchart .row:hover {
background-color: #f5f5f5; 
}  */

/* 
attendance.css */

.attendence-chart {
  margin-top: 50px;
}

/* notice-board.css */
.notice-board {
  margin: 10px;
}

#notice-board-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
}

#notice-card-header {
  background-color: #5458af;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

#notice-header-title {
  margin: 0;
  color: white;
}

#notice-card-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.sender-info {
  flex-grow: 1;
}

.sender-name {
  font-weight: bold;
  margin: 0;
}

.sender-role {
  margin: 0;
}

.notice {
  margin: 0;
}

.quickguide-container {
  padding: 30px 0px;
  border: 1px solid #c8c9f9;
  border-radius: 5px;
}

.quickguide-button {
  border: 1px solid #c8c9f9;
  padding: 5px 15px;
  background-color: #f9f9ff;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  margin-top: 5px;
  font-size: 12px;
  color: var(--maincolor);
  font-weight: 500;
}

/* New Student Dashboard */

.dashboard__welcome {
  background-color: #f9f9ff;
  border: 1px solid #c8c9f9;
  border-radius: 5px;
  padding: 10px 15px;
}

.dashboard__welcome-text {
  font-weight: 500;
  color: var(--maincolor);
  font-size: 18px;
}

.dashboard__container {
  border: 2px solid #edeff7;
  border-radius: 5px;
  padding: 10px 15px;
}

.dashboard__container-title {
  display: block;
  color: #303030;
  font-size: 14px;
  font-weight: 600;
}

.dashboard__container-text {
  font-size: 10px;
  color: #5f6276;
}

.announcement__card {
  border-top: 1px solid #edeef7;
  border-bottom: 1px solid #edeef7;
  padding: 12px 0px;
}

.announcement__title,
.document__title {
  font-weight: 500;
  font-size: 13px;
}

.document__icon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: #f0f0ff;
}

.announcement__breadcrumb,
.document__breadcrumb {
  font-size: 8px !important;
  font-weight: 500 !important;
}

.announcement__content,
.document__content {
  font-size: 10px;
}

.dashboard__count-cards-pink {
  background-image: linear-gradient(to right, #d53364, #f249a4);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #ffcedd;
}

.dashboard__count-cards-blue {
  background-image: linear-gradient(to right, #7f83db, #84c1e1);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #edeef7;
}

.dashboard__count-cards-green {
  background-image: linear-gradient(to right, #4aa96d, #7dc3ab);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #ceffee;
}

.dashboard__count-cards-purple {
  background-image: linear-gradient(to right, #7665a8, #ad569b);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #d7caff;
}

.dashboard__count-cards-orange {
  background-image: linear-gradient(to right, #f17a41, #ffce60);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #fce5af;
}

.dashboard__count-cards-dodger {
  background-image: linear-gradient(to right, #238bce, #00c6ff);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #c6f3ff;
}

.dashboard__user-text {
  color: #fff;
  font-weight: normal;
  font-size: 10px;
}

.dashboard__user-count {
  color: #fff;
}

.chart-container {
  flex-wrap: wrap;
}

/* Receivables */
.receivables-row {
  padding: 8px 10px;
  border-bottom: 1.2px solid #edeff7;
}
.receivables-title {
  color: #939393;
  font-size: 12px;
  font-weight: 500;
}

.receivables-amount {
  font-weight: bold;
}

/* Revenue */
.revenue-container {
  background-image: linear-gradient(to right, #f17a41, #ffce60);
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #fce5af;
}

.revenue-text {
  color: #fff;
  font-size: 10px;
}

.revenue-amount {
  color: #fff;
}

.revenue__icon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 207, 96, 0.2);
}

.dashboard-subheading {
  font-size: 12px;
  color: #2d2d2d;
  font-weight: 600;
}

.revenue-row-text {
  font-size: 12px;
}

/* Payment Overdue */
.payment-overdue-container {
  background-color: #FAFBFC;
  border-radius: 5px;
  border-left: 4px solid #238bce;
}

.reminder-text {
  font-size: 10px;
  color:  #A2A5B5;
}
.progress_bar{
    height: 140px;
    margin-top: '-20px';
    max-width: "100%";
    background-color: #fafafa;
    border: none;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior:smooth;
}
.progress_bar::-webkit-scrollbar{
    display: none;
}

.user-list-row {
    display: flex;
    flex-direction: row;
}

@media (max-width: 767px) {
    .user-list-row {
        flex-direction: column-reverse;
    }
}
.my-custom-scrollbar {
  position: relative;
  height: 110vh;
  width: auto;
  overflow: auto;
  overflow-x: hidden;
}

.table-wrapper-scroll-y {
  display: block;
}

.dataTables_empty {
  opacity: 0;
}

.editor-class {
  padding: 1rem;
  border: 1px solid #e4e6ef;
}

.parent-class {
  max-width: 1200px;
  /* margin: 0 auto; */
  display: grid;
  grid-gap: 1rem;
}

.single-column {
  grid-template-columns: repeat(1, 1fr) !important;
}

/* For the images on the question paper */
figure {
  margin: 1em 5px;
}

/* .my-table {
  margin-top: -4.75rem;
} */

/* .questions-table {
    margin-top: -4.5rem;
} */

/* Screen larger than 600px? 2 column */
@media (min-width: 600px) {
  .parent-class {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Screen larger than 900px? 4 columns */
@media (min-width: 900px) {
  .parent-class:not(.single-column) {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .create-questions-actions {
    justify-content: start !important;
    padding-top: 30px;
  }

  /* .my-table {
    margin-top: 0rem;
  } */

  .questions-table {
    margin-top: 0rem;
  }
}

@media (max-width: 991.98px) {
  .create-questions-actions {
    justify-content: start !important;
    padding-top: 30px;
  }
}

h1,
h2,
h3,
h4,
h5,
p,
label,
th,
td,
input,
select,
MenuItem {
  font-family: "Poppins", sans-serif !important;
}

.question-right-container {
  overflow-y: auto;
  height: 630px;
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .question-right-container {
    height: 900px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .question-right-container {
    height: 960px;
  }
}
.settings-menu {
  width: 20px;
  height: 50px;
  position: absolute;
  background-color: #fff;
  color: var(--maincolor);
  border-radius: 2px;
  cursor: pointer;
  border: none;
}

#cmc .settings-menu {
  width: 20px;
  height: 50px;
  position: absolute;
  background-color: #fff;
  color: var(--maincolor-cmc);
  border-radius: 2px;
  cursor: pointer;
  border: none;
}

@media (max-width: 1000px) {
  .settings-modal {
    display: none;
  }
}
.grade-list {
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* 12 items per row */
    grid-gap: 10px;
    gap: 10px; /* Optional, to add space between items */
    margin-top: 10px;
  }
  
  .grade-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
.filterCardContainer {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #d1d1d1 transparent;
  /* For Firefox */
}

.filterCardContainer::-webkit-scrollbar {
  height: 8px;
  /* Horizontal scrollbar height */
}

.filterCardContainer::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 4px;
}

.filterCardContainer::-webkit-scrollbar-track {
  background: transparent;
}

.filterAccordionSummary {
  margin: 0px !important;
}

@media (max-width: 1150px) {
  .css-j0ozid {
    display: flex !important;
    flex-direction: column !important;
    align-items: start !important;
  }
}
/* PaperMapping.js */
.sticky-card-body {
    background-color: #fff;
    z-index: 1;
}

@media only screen and (max-width: 991px) {
    .filterBySec {
        padding-left: 12.5px !important;
    }

    .searchBySec {
        margin-bottom: -2.4rem !important;
    }
}

.filterCard {
    margin: 8px;
    padding: 8px;
    border: 2px #e0e0e0 solid;
    border-radius: 12px;
    overflow-y: auto;
}

.ClearbuttonFilter {
    padding: 0px 8px !important;
    height: 32px;
    font-weight: 500 !important;
}

.ClearbuttonFilter:hover {
    background-color: #074aaf !important;
    color: white;
}

.filterpprs{
    background-color: #f3f3f3;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 8px;
    border-radius: 8px;
}
.filterCardContainer {
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #d1d1d1 transparent;
    /* For Firefox */
  }
  
  .filterCardContainer::-webkit-scrollbar {
    height: 8px;
    /* Horizontal scrollbar height */
  }
  
  .filterCardContainer::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 4px;
  }
  
  .filterCardContainer::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .filterAccordionSummary {
    margin: 0px !important;
  }
