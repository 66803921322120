.swal-text,
.swal-title {
  text-align: center;
  color: #2c2e3a;
}

.swal-modal {
  background-color: #fff;
}

.swal-icon--custom {
  width: 150px;
  height: 150px;
}

.swal-icon--success__hide-corners {
  background: none !important;
}
.swal-icon--success:before,
.swal-icon--success:after {
  background: none !important;
}
