.grade-list {
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* 12 items per row */
    gap: 10px; /* Optional, to add space between items */
    margin-top: 10px;
  }
  
  .grade-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  