:root {
  --primaryCover-color: #133461;
  --secondaryCover-color: #BEC7CF;
  --thirdyCover-color: #ffffff;
}

.body {
  background-color: white;
  margin: 20px 100px !important;
}

@media only screen and (max-width: 600px) {
  .body {
    margin: 20px !important;
  }
}

.headerBar {
  background-color: var(--primaryCover-color);
  height: 20px;
}

.MuiGrid-root {
  padding: 0px !important;
  margin: 0px !important;
}

.personIcon {
  background-color: var(--secondaryCover-color);
  margin: 5px;
  border-radius: 50px;
  height: 40px;
  width: 40px;
}

.PIcon {
  height: 20px !important;
  width: 20px !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alignItemsCenter {
  display: flex;
  align-items: center;
}

.alignItemsRight {
  display: flex;
  justify-content: end;
}

.alignItemsLeft {
  display: flex;
  justify-content: start;
}

.justifyContentCenter {
  display: flex;
  justify-content: center;
}

.justifyContentLeft {
  display: flex;
  justify-content: start;
}

.justifyContentRight {
  display: flex;
  justify-content: end;
}

.button1 {
  background-color: var(--primaryCover-color) !important;
  height: 50px;
  margin-left: 10px !important;
  font-family: Poppins, Helvetica, sans-serif !important;
  font-weight: 500;
  text-transform: none !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column !important;
}

.links {
  background-image: linear-gradient(to right, #133461, #CED8E5);
  border-left: 5px solid var(--secondaryCover-color);
  color: var(--thirdyCover-color);
  border-radius: 5px;
  padding: 10px !important;
  margin-bottom: 20px !important;
}

.link {
  background-image: linear-gradient(to right, #cdd5df, #f4f8fc);
  border-left: 5px solid var(--primaryCover-color);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  margin-right: 5px;
  width: 95%;
}

.study {
  height: 100px;
  margin: 0px 20px;
}

.cursive {
  font-family: 'Birthstone', cursive;
  font-size: 24px;
}

.menu_board {
  height: 16px;
  margin-right: 10px;
}

.menu_board_blue {
  height: 16px;
  margin-right: 10px;
}

.cgclogo {
  width: 80px;
}

.welcome {
  gap: 10px;
  text-align: center;
}

.text01 {
  font-size: 16px;
  color: var(--primaryCover-color);
}

.text02 {
  font-size: 28px;
  color: var(--primaryCover-color);
  margin: 20px;
}

.text03 {
  font-size: 12px;
  color: var(--primaryCover-color);
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
}

.text04 {
  font-size: 12px;
  color: #2F80ED;
  margin: 5px 0px;
  cursor: pointer;
}

.hr {
  border-bottom: 1px solid var(--secondaryCover-color);
  margin: 30px 0px;
}

.hr2 {
  border-bottom: 1px solid var(--secondaryCover-color);
  margin: 2px 10px;
  width: 80%;
}

.sets {
  border: 1px solid var(--secondaryCover-color);
  border-radius: 5px;
  margin: 10px !important;
}

.courses {
  border-left: 5px solid #5500FF;
  background-color: #E1D2FF;
  border-radius: 5px;
  margin: 5px !important;
  gap: 5px;
}

.announcement {
  border-radius: 5px;
  margin: 5px !important;
  gap: 10px;
}

.ann1 {
  border: 2px solid #FEB446;
}

.ann2 {
  border: 1px solid #F18A8A;
}

.ann_body{
font-size: 10px;
font-weight: 500;
}

.announcementTopic{
  font-size: 14px;
  border-bottom: 3px;
}

.ann_date{
  font-size: 8px;
  font-weight: 500;
}

.chip {
  border-radius: 2px;
  padding: 2px;
  font-size: 8px;
}

.chip1 {
  background-color: #FFEBDB;
  color: #FEB446;
  margin-left: 10px;
}

.chip2 {
  background-color: #FBDDDD;
  color: #EB5757;
}

.edu {
  margin: 5px;
}

.cover {
  width: 100% !important;
}

@media only screen and (min-width: 1600px) {
  .text01 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1400px) {
  .text01 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .topbar {
    height: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .personIcon {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .text01 {
    font-size: 8px;
  }

  .text02 {
    font-size: 12px;
  }

  .link {
    width: 100%;
  }

  .study {
    height: 50px;
    margin: 0px 10px;
  }

  .button1 {
    height: 30px;
  }

  .btntxt{
    font-size: 8px;
  }

  .hand{
    height: 10px;
  }
}
.custom-calendar .rbc-month-view {
  border: none;
}

.custom-calendar .rbc-header {
  border-bottom: none;
}

.custom-calendar .rbc-row-bg {
  border-top: none;
}

.custom-calendar .rbc-time-header {
  border-right: none;
}
