@media (max-width:1200px) {
  .subPackTitles {
    font-size: 12px;
  }
}

@media (max-width:1080px) and (min-width:768px) {
  .subPackTitles {
    height: 32px;
    overflow: auto;
  }
}