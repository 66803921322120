/* PaperMapping.js */
.sticky-card-body {
    background-color: #fff;
    z-index: 1;
}

@media only screen and (max-width: 991px) {
    .filterBySec {
        padding-left: 12.5px !important;
    }

    .searchBySec {
        margin-bottom: -2.4rem !important;
    }
}

.filterCard {
    margin: 8px;
    padding: 8px;
    border: 2px #e0e0e0 solid;
    border-radius: 12px;
    overflow-y: auto;
}

.ClearbuttonFilter {
    padding: 0px 8px !important;
    height: 32px;
    font-weight: 500 !important;
}

.ClearbuttonFilter:hover {
    background-color: #074aaf !important;
    color: white;
}

.filterpprs{
    background-color: #f3f3f3;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 8px;
    border-radius: 8px;
}
.filterCardContainer {
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #d1d1d1 transparent;
    /* For Firefox */
  }
  
  .filterCardContainer::-webkit-scrollbar {
    height: 8px;
    /* Horizontal scrollbar height */
  }
  
  .filterCardContainer::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 4px;
  }
  
  .filterCardContainer::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .filterAccordionSummary {
    margin: 0px !important;
  }