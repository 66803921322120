/* .royal-landing-bg {
  height: 100vh;
  background-position: center top;
  background-size: cover;
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-color: black;
} */

.royal-landing-bg  {
  background-image: url("../../../assets/images/royal-bg.png");
  background-size: cover;
  background-repeat: repeat-y;
  background-color: black;
  height: 110lvh;
}

.royal-logo-img {
  max-width: 200px;
  max-height: 95px;
}

@media (min-width: 576px) and (max-width: 1024px) {
  .royal-landing-bg {
    background-image: url("../../../assets/images/royal-bg.png");
    height: 120vh;
  }
}

/*desktops - higher resolution*/
@media (max-width: 1281px) {
  .royal-landing-bg {
    background-image: url("../../../assets/images/royal-bg.png");
    height: 120vh;
  }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .royal-landing-bg {
    background-image: url("../../../assets/images/royal-bg.png");
    height: 130vh;
  }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
  .royal-landing-bg {
    background-image: url("../../../assets/images/royal-bg.png");
  }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .royal-landing-bg {
    background-image: url("../../../assets/images/royal-bg.png");
  }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
  .royal-landing-bg {
    background-image: url("../../../assets/images/royal-bg.png");
    height: 180vh;
  }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
  .royal-landing-bg {
    background-image: url("../../../assets/images/royal-bg.png");
    height: 180vh;
  }
}

@media (max-width: 319px) {
  .royal-landing-bg {
    background-image: url("../../../assets/images/royal-bg.png");
    height: 180vh;
  }
}
