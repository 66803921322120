.login-bg{
    background: var(--bgcolor);
    height:100vh;
    
}
.login-card-title{
    font-family: var(--secondary-font);
    color: var(--textcolor);
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}
.login-card{
    border-radius: 10px;
    border:0;
    margin-right: auto;
    margin-left: auto;
}
.login-label{
    font-family: var(--secondary-font);
    color: var(--textcolor);
}
.login-input{
    color: #6e6b7b;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    padding: 14px 16px;
}
.login-input:focus, .login-input:active, .login-input:focus-within {
    outline: 0;
    box-shadow: none;
    border-color: var(--maincolor);
}
.login-btn{
    font-family: var(--secondary-font);
    height: 45px;
    font-weight: 500;
    font-size: 15px;
}
.login-btn:hover{
    box-shadow: 0 8px 25px -8px var(--maincolor);
}
.login-text{
    font-family: 'Montserrat';
    font-weight: 500;
}
.login-sub-text{
    color:var(--textcolor);
}
.microsoft-btn{
    font-family: 'Montserrat';
    height: 45px;
    font-weight: 500;
    font-size: 15px;
    width:100%;
}
.x-icon {
    height: 20px;
    width:  20px;
    top: .125em;
    right:0;
    /* position: relative; */
  }

/*desktops - higher resolution*/
@media (min-width: 1281px){
    .login-card-body{
        padding-left: 80px;
        padding-right: 80px;
    }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
    .login-card-body{
        padding-left: 50px;
        padding-right: 50px;
    }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px){
    .login-card-body{
        padding-left: 50px;
        padding-right: 50px;
    }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .login-card-body{
        padding-left: 50px;
        padding-right: 50px;
    }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px){
    .login-card-body{
        padding-left: 20px;
        padding-right: 20px;
    }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px){

}