.my-custom-scrollbar {
  position: relative;
  height: 110vh;
  width: auto;
  overflow: auto;
  overflow-x: hidden;
}

.table-wrapper-scroll-y {
  display: block;
}

.dataTables_empty {
  opacity: 0;
}

.editor-class {
  padding: 1rem;
  border: 1px solid #e4e6ef;
}

.parent-class {
  max-width: 1200px;
  /* margin: 0 auto; */
  display: grid;
  grid-gap: 1rem;
}

.single-column {
  grid-template-columns: repeat(1, 1fr) !important;
}

/* For the images on the question paper */
figure {
  margin: 1em 5px;
}

/* .my-table {
  margin-top: -4.75rem;
} */

/* .questions-table {
    margin-top: -4.5rem;
} */

/* Screen larger than 600px? 2 column */
@media (min-width: 600px) {
  .parent-class {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Screen larger than 900px? 4 columns */
@media (min-width: 900px) {
  .parent-class:not(.single-column) {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .create-questions-actions {
    justify-content: start !important;
    padding-top: 30px;
  }

  /* .my-table {
    margin-top: 0rem;
  } */

  .questions-table {
    margin-top: 0rem;
  }
}

@media (max-width: 991.98px) {
  .create-questions-actions {
    justify-content: start !important;
    padding-top: 30px;
  }
}

h1,
h2,
h3,
h4,
h5,
p,
label,
th,
td,
input,
select,
MenuItem {
  font-family: "Poppins", sans-serif !important;
}

.question-right-container {
  overflow-y: auto;
  height: 630px;
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .question-right-container {
    height: 900px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .question-right-container {
    height: 960px;
  }
}