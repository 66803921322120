/* Updated CSS */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.container-terms {
  margin: 50px auto;
  width: 70%;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f7f7f7;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333333;
}

.contents {
  margin-bottom: 20px;
  color: #666666;
  text-align: justify;
  line-height: 1.6;
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: #333333;
}

.checkbox-custom {
  /* appearance: none; */
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s;
  vertical-align: middle;
}

.checkbox-custom:checked {
  border-color: #0056b3;
  background-color: #0056b3;
}

.checkbox-text {
  font-weight: 500;
  vertical-align: middle;
}

.language-label {
  display: block;
  margin-bottom: 10px;
  color: #333333;
  font-weight: 500;
  text-align: right;
}

.language-select {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  color: #333333;
  font-size: 14px;
  width: 10%;
  vertical-align: middle;
}

/* Adjusted dropdown arrow */
.language-select:after {
  content: "\25BE";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, opacity 0.3s;
}

.submit-button:disabled,
.submit-button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .container-terms {
    width: 90%;
  }

  .language-label {
    text-align: left;
  }

  .language-select {
    width: 100%;
  }
}

.submit-button-back {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ccc;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, opacity 0.3s;
  align-self: flex-start; 
  margin-bottom: 10px; 
}

.submit-button-back:hover {
  background-color: #999; 
}
