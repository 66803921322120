.packageCard {
  border-radius: 16px;
  min-height: 400px;
  box-shadow: 0 0px 2px 1px rgba(219, 4, 129, 0.3);
}

@media (max-width: 1150px) and (min-width: 1000px) {
  .packageCard {
    min-height: 450px !important;
  }

  .disabledPackageCard {
    min-height: 450px !important;
  }
}

.packageCard:hover {
  box-shadow: 0 1px 3px 5px rgba(235, 152, 29, 0.2);
  transition: box-shadow 0.3s;
}

.disabledPackageCard {
  border-radius: 16px;
  min-height: 400px;
  box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.3);
}

.packName {
  font-size: 14px;
  font-weight: 600;
}

.packDis {
  font-size: 12px;
  font-weight: 400;
}

.packTopic {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: #E0ECFF;
  color: #145ACB;
}

.disabledPackTopic {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: #b9b9b9;
  color: #ffffff;
}

.packValue {
  font-size: 20px;
  font-weight: 400;
}

.promoInput {
  background-color: #f5f5f5;
  color: #ABABAB;
}

.applyBtn {
  background-color: #FC6C6C !important;
  font-size: 12px;
}

.packHr1 {
  width: 20%;
  height: 2px;
  color: #31A0FE;
  display: flex;
  justify-content: start;
  margin: 12px !important;
}

.packCredit {
  font-size: 24px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin-bottom: 16px;
}

.packPromo {
  font-size: 10px !important;
  background-color: #FC6C6C;
  padding: 4px 10px;
  margin: 8px 0px;
  border-radius: 4px;
  color: white;
  width: fit-content;
}

.packPrice {
  font-size: 14px;
  padding: 4px;
}

.packHr2 {
  color: #D9DEEF;

}

.packbtn {
  background-color: #FC676C !important;
  color: white !important;
  font-size: 18px;
  font-weight: 600 !important;
  margin-top: 16px !important;
}

.packbtnDisabled {
  background-color: #b9b9b9 !important;
  color: white !important;
  font-size: 18px;
  font-weight: 600 !important;
  margin-top: 16px !important;
}

.packDetails1 {
  font-size: 12px;
  padding: 4px;
}

.packDetails2 {
  font-size: 10px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin-top: 4px;
}

/* styles.css */
.background-animation {
  position: fixed;
  /* Make it cover the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(203, 203, 255, 0.4);
  /* Your desired background color */
  z-index: 9999;
  /* Ensure it's on top of other content */
  display: flex;
  justify-content: center;
  /* Center the Lottie animation */
  align-items: center;
  /* Center the Lottie animation */
}